import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  wrapper: {
    width: "100%",
    maxWidth: 1366,
    minWidth: 0,
    backgroundColor: "white",
    position: "relative"
  },
  
  app: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    position: "relative",
    backgroundColor: "#212e36",
    minHeight: 486,
    maxHeight: 829,
  },
});

export default styles;