import React, { Component } from 'react';
import {View, TextInput, StyleSheet, Image} from 'react-native';
import PropTypes from 'prop-types';
import UIElementText from '../uiElementText';
import selectionButtonImage from "../../images/selectionButton.png";

class NumberOfHingesButton extends Component {

  shouldComponentUpdate(nextProps) {
    return (nextProps.ConfiguratorState.torque.numberOfHinges !== this.props.ConfiguratorState.torque.numberOfHinges);
  }

  numberOfHingesChanged(num) {
    this.props.torqueChanged({numberOfHinges: this.validateNumberOfHinges(num) });
  }

  validateNumberOfHinges(value) {
    if (value === '') return value;
    const intValue = parseInt(value, 10);

    if (intValue > 0) {
      return intValue;
    }

    return this.props.ConfiguratorState.torque.numberOfHinges;
  }

  render() {
    const torqueStyles = this.props.styleSheet

    return (
      <View style={torqueStyles.torqueControlsButton} >
        <Image resizeMode="stretch" style={torqueStyles.backgroundImage} source={selectionButtonImage}/>
        <View style={torqueStyles.torqueControlsButtonPadding}>
          <UIElementText
            style={StyleSheet.flatten(torqueStyles.torqueControlsButtonText)}
            languageItemKey="number_of_hinges"
            defaultText="NUMBER OF HINGES" />
          <TextInput type="text"
            maxLength={6}
            style={torqueStyles.torqueControlsButtonValue}
            value={this.props.ConfiguratorState.torque.numberOfHinges.toString()}
            onChangeText={this.numberOfHingesChanged.bind(this)} />
        </View>
      </View>
    );
  }
}

NumberOfHingesButton.propTypes = {
  ConfiguratorState: PropTypes.object,
  torqueChanged: PropTypes.func
};

export default NumberOfHingesButton;
