import fetch from 'isomorphic-fetch';
import ConfigVariables from '../configVariables';

function doesFileExist(path) {
  return new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(path, resolve, reject);
  });
}

function fetchLocal(url) {
  return doesFileExist(url)
    .then(() => {
      return new Promise((resolve, reject)=> {
        const xhr = new XMLHttpRequest;
        xhr.onload = ()=> {
          resolve(new Response(xhr.responseText));
        };
        xhr.onerror = ()=> {
          reject();
        };
        xhr.open('GET', url);
        xhr.send(null);
      });
    }).catch(() => {
      console.log(`${url} not found`);
    });
}

function saveToDataDir(response, name) {
  window.resolveLocalFileSystemURL(
    `${cordova.file.dataDirectory}`,
    (dirEntry) => {
      dirEntry.getFile(
        `${name}.json`,
        {create: true},
        (fileEntry) => {
          fileEntry.createWriter(
            (fileWriter) => {
              fileWriter.write(JSON.stringify(response));
            },
            () => {
              console.log('failed to write file');
            }
          );
        }, () => {
          console.log('failed to get file for writing');
        });
    },
    () => {
      console.log('failed to get dir to write to');
    }
  );
}

export default function getJson(name, dispatch) {
  if (process.env.PHONEGAP) {
    return fetch(`https://${ConfigVariables.adminServer}${name}.json`)
      .then(response => { return response.json(); } )
      .then(json => { saveToDataDir(json, name); dispatch(json); } )
      .catch(() => {
        return fetchLocal(`${cordova.file.dataDirectory}${name}.json`)
          .then(response => { return response.json(); } )
          .then(json => { dispatch(json);})
          .catch(() => {
            return fetchLocal(`${cordova.file.applicationDirectory}www/data/${name}.json`)
              .then(response => { return response.json(); } )
              .then(json => { saveToDataDir(json, name); dispatch(json); })
              .catch(() => {
                console.log('failed to load data from files.');
              });
          });
      });
  }

  return fetch(`https://${ConfigVariables.adminServer}${name}.json`)
    .then(response => { return response.json(); } )
    .then(json => { dispatch(json); } );
}
