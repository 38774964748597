import { configureStore } from '@reduxjs/toolkit'
import rootReducer from "../reducers";
// import thunkMiddleware from "redux-thunk";

// if (process.env.NODE_ENV === 'production') {
//   module.exports = require('./configureStore.production'); // eslint-disable-line global-require
// } else {
//   module.exports = require('./configureStore.development'); // eslint-disable-line global-require
// }

export default function store(initialState) {
  return configureStore({
    reducer: rootReducer,
    initialState
  });
}
