import React, { Component } from 'react';
import {View, Image, Animated, Platform} from 'react-native';
import PropTypes from 'prop-types';
import LanguageItemText from '../languageItemText';
import ClickTap from '../clickTap';

import activeImage from '../../images/selectedProductOverlay.png';
import {Layouts} from "../../layout";

class ResultItem extends Component {
  state = { animation: new Animated.Value(0)};

  shouldComponentUpdate(nextProps) {
    return this.props.active !== nextProps.active ||
      this.props.product.id !== nextProps.product.id ||
      nextProps.ConfiguratorState.selectionParameterEnabled !== this.props.ConfiguratorState.selectionParameterEnabled ||
      nextProps.ConfiguratorState.productEnabled !== this.props.ConfiguratorState.productEnabled || 
      nextProps.ConfiguratorState.style.width !== this.props.ConfiguratorState.style.width ||
      nextProps.ConfiguratorState.style.height !== this.props.ConfiguratorState.style.height ||
      nextProps.ConfiguratorState.style.layout !== this.props.ConfiguratorState.style.layout;
  }

  clicked(e) {
    this.props.productClicked(this.props.product.id);
  }

  render() {
    const resultsStyles = this.props.stylesheet;
    const disabled = (this.props.ConfiguratorState.productEnabled !== null || this.props.ConfiguratorState.selectionParameterEnabled !== null) &&
        (this.props.ConfiguratorState.style.layout === Layouts.PHABLET ||
        this.props.ConfiguratorState.style.layout === Layouts.PHONE);
    
    let image = '';
    if (this.props.product.base64_image_small) {
      let uri = "data:image/png;base64," + this.props.product.base64_image_small.replace(/\n/g, '');
      image = (
        <Animated.Image source={{uri: uri}}
                        style={[{width: 70, height: 45}, resultsStyles.resultItemImage, {
                          transform: [{
                            scale: this.state.animation.interpolate({
                              inputRange: [0,1],
                              outputRange: [1,1.15]
                            })
                          }]
                        }]} />
      );
    }
    return (
      <ClickTap
        onMouseEnter={() => {
          Animated.timing(this.state.animation, {
            toValue: 1,
            duration: 200,
            useNativeDriver: Platform.OS !== 'web'
          }).start();
        }}
        onMouseLeave={() => {
          Animated.timing(this.state.animation, {
            toValue: 0,
            duration: 200,
            useNativeDriver: Platform.OS !== 'web'
          }).start();
        }}
        style={[resultsStyles.resultItem, this.props.active ? resultsStyles.resultItemActive : null]}
        disabled={disabled}
        onClick={this.clicked.bind(this)}>
        {image}
        <View style={resultsStyles.resultItemTextWrapper}>
          <LanguageItemText
            style={resultsStyles.resultItemTitle}
            languageItemKey="title"
            defaultText={this.props.product.name}
            languageItems={this.props.product.language_items} />
          <LanguageItemText
            style={resultsStyles.resultItemTagline}
            languageItemKey="tagline"
            defaultText={''}
            languageItems={this.props.product.language_items} />
        </View>
        <View style={{flex: 1, width: "100%", position: "absolute"}}/>
        {this.props.active &&
          <Image resizeMode="stretch" source={activeImage} style={resultsStyles.activeImage} />
        }
      </ClickTap>
    );
  }
}

ResultItem.propTypes = {
  product: PropTypes.object,
  active: PropTypes.bool,
  stylesheet: PropTypes.object,
  ConfiguratorState: PropTypes.object,
  productClicked: PropTypes.func
};

export default ResultItem;
