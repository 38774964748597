import fetch from 'isomorphic-fetch';
import { Dimensions, Platform } from "react-native";
import { Layouts, getLayout } from "../layout";
import ConfigVariables from '../configVariables';

export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';
export function selectLanguage(languageID, commaAsSeparator) {
  return {
    type: SELECT_LANGUAGE,
    languageID: languageID,
    commaAsSeparator: commaAsSeparator
  };
}

export const CLOSE_MODALS = 'CLOSE_MODALS';
export function closeModals() {
  return {
    type: CLOSE_MODALS
  };
}

export const LOAD_COMPLETE = 'LOAD_COMPLETE';
export function loadComplete() {
  return {
    type: LOAD_COMPLETE
  };
}

export const SELECTION_PARAMETER_CLICKED = 'SELECTION_PARAMETER_CLICKED';
export function selectionParameterClicked(selectionParameterID) {
  return {
    type: SELECTION_PARAMETER_CLICKED,
    selectionParameterID: selectionParameterID
  };
}

export const SELECTION_PARAMETER_OPTION_CLICKED = 'SELECTION_PARAMETER_OPTION_CLICKED';
function _selectionParameterOptionClicked(selectionParameterID, selectionParameterOptionID, products) {
  return {
    type: SELECTION_PARAMETER_OPTION_CLICKED,
    selectionParameterID: selectionParameterID,
    selectionParameterOptionID: selectionParameterOptionID,
    products: products
  };
}
export function selectionParameterOptionClicked(selectionParameterID, selectionParameterOptionID) {
  return (dispatch, getState) => {
    return dispatch(_selectionParameterOptionClicked(selectionParameterID, selectionParameterOptionID, getState().Products.data));
  };
}

export const CONTACT_ME_NOW_CLICKED = 'CONTACT_ME_NOW_CLICKED';
export function contactMeNowClicked() {
  return {
    type: CONTACT_ME_NOW_CLICKED
  };
}

export const CONTACT_ME_NOW_FORM_CHANGED = 'CONTACT_ME_NOW_FORM_CHANGED';
export function contactMeNowFormChanged(formData) {
  return {
    type: CONTACT_ME_NOW_FORM_CHANGED,
    formData: formData
  };
}

export const CONTACT_ME_NOW_FORM_SUBMITTED = 'CONTACT_ME_NOW_FORM_SUBMITTED';
export function contactMeNowFormSubmitted() {
  return {
    type: CONTACT_ME_NOW_FORM_SUBMITTED
  };
}

export const CONTACT_ME_NOW_FORM_SUCCESS = 'CONTACT_ME_NOW_FORM_SUCCESS';
export function contactMeNowFormSuccess() {
  return {
    type: CONTACT_ME_NOW_FORM_SUCCESS
  };
}

export const CONTACT_ME_NOW_FORM_FAILURE = 'CONTACT_ME_NOW_FORM_FAILURE';
export function contactMeNowFormFailure() {
  return {
    type: CONTACT_ME_NOW_FORM_FAILURE
  };
}

export function postContactForm() {
  return (dispatch, getState) => {
    dispatch(contactMeNowFormSubmitted());
    const configuratorState = Object.assign({}, getState().ConfiguratorState);
    delete configuratorState.filteredProducts;
    const selectedProducts = [];
    for (const product of getState().ConfiguratorState.filteredProducts) {
      selectedProducts.push(product.name);
    }
    configuratorState.selectedProducts = selectedProducts;
    return fetch(`https://${ConfigVariables.adminServer}commentForm.json`, {
      method: 'POST',
      mode: 'cors',
      cache: 'default',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: `data=${JSON.stringify({
        contactForm: configuratorState
      })}`
    })
      .then(response => { return response.json(); } )
      .then(json => { dispatch(contactMeNowFormSuccess(json)); } )
      .catch(() => { dispatch(contactMeNowFormFailure()); });
  };
}

export const PRODUCT_CLICKED = 'PRODUCT_CLICKED';
export function productClicked(productID) {
  return {
    type: PRODUCT_CLICKED,
    productID: productID
  };
}

function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if(pair[0] === variable) {return pair[1];}
  }
  return(false);
}

export function initializeLanguage(languages) {
  return (dispatch) => {
    // const languageQuery = getQueryVariable('language');
    let selectedLanguage = null;

    // if (languageQuery) {
    //   selectedLanguage = languages.find((language) => { return language.name.toUpperCase() === languageQuery.toUpperCase(); });
    // }

    if (process.env.PHONEGAP && window.Intl && typeof window.Intl === 'object') {
      const languageCode = navigator.language.toUpperCase().substring(0, 2);
      let language = 'ENGLISH';
      switch(languageCode) {
        case 'EN':
          language = 'ENGLISH';
          break;
        case 'FR':
          language = 'FRENCH';
          break;
        case 'DE':
          language = 'GERMAN';
          break;
        case 'ZH':
          language = 'CHINESE';
          break;
        default:
          language = 'ENGLISH';
      }
      selectedLanguage = languages.find(lang => { return lang.name.toUpperCase() === language; });
    }

    if (!selectedLanguage) {
      selectedLanguage = languages.find(language => { return language.name.toUpperCase() === 'ENGLISH'; });
    }
    if (selectedLanguage) {
      dispatch(selectLanguage(selectedLanguage.id, selectedLanguage.comma_as_separator));
    } else {
      dispatch(selectLanguage(languages[0].id, languages[0].comma_as_separator));
    }
  };
}

export function initializeSelectionParameters(selectionParameters) {
  return (dispatch) => {
    for (const selectionParameter of selectionParameters) {
      for (const selectionParameterOption of selectionParameter.selection_parameter_options) {
        dispatch(selectionParameterOptionClicked(selectionParameter.id, selectionParameterOption.id));
      }
    }
  };
}

export const CALCULATE_TORQUE_CLICKED = 'CALCULATE_TORQUE_CLICKED';
export function calculateTorqueClicked() {
  return {
    type: CALCULATE_TORQUE_CLICKED
  };
}

export const TORQUE_BUTTON_CLICKED = 'TORQUE_BUTTON_CLICKED';
export const TORQUE_WINDOW_STATES = {
  UNIT_OF_MEASURE: 'UNIT_OF_MEASURE',
  PRODUCT_WEIGHT: 'PRODUCT_WEIGHT',
  LENGTH: 'LENGTH',
  CENTER_OF_GRAVITY: 'CENTER_OF_GRAVITY',
  ROTATION_ANGLE: 'ROTATION_ANGLE',
  NUMBER_OF_HINGES: 'NUMBER_OF_HINGES'
};
export function torqueButtonClicked(torqueWindowState) {
  return {
    type: TORQUE_BUTTON_CLICKED,
    torqueWindowState: torqueWindowState
  };
}

export const TORQUE_CHANGED = 'TORQUE_CHANGED';
export function _torqueChanged(torqueHash, products) {
  return {
    type: TORQUE_CHANGED,
    torqueHash: torqueHash,
    products: products
  };
}
export function torqueChanged(torqueHash) {
  return (dispatch, getState) => {
    return dispatch(_torqueChanged(torqueHash, getState().Products.data));
  };
}

export const RESET_TORQUE_CALCULATOR = 'RESET_TORQUE_CALCULATOR';
export function _resetTorqeCalculator(products) {
  return {
    type: RESET_TORQUE_CALCULATOR,
    products: products
  };
}
export function resetTorqeCalculator() {
  return (dispatch, getState) => {
    return dispatch(_resetTorqeCalculator(getState().Products.data));
  };
}

export const RESET_ALL = 'RESET_ALL';
export function _resetAll(products, selectionParameters) {
  return {
    type: RESET_ALL,
    products: products,
    selectionParameters: selectionParameters
  };
}
export function resetAll() {
  return (dispatch, getState) => {
    return dispatch(_resetAll(getState().Products.data, getState().SelectionParameters.data));
  };
}

export const CALCULATE_STYLE = 'CALCULATE_STYLE';
export function calculateStyle() {
  return (dispatch, getState) => {
    return dispatch(_calculateStyle());
  }
}
function _calculateStyle() {
  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  var layout = getLayout(windowWidth);

  let headerHeight = (layout === Layouts.PHABLET || layout === Layouts.PHONE) ? 156 : 196;
  headerHeight += (Platform.OS === "ios" && (layout === Layouts.PHABLET || layout === Layouts.PHONE)) ? 40 : 0;
  let footerHeight = layout === Layouts.PHABLET || layout === Layouts.PHONE ? 80 : 50;
  let appHeight = windowHeight - headerHeight - footerHeight;
  if (Platform.OS === "web" && layout === Layouts.TABLET && appHeight < 740) appHeight = 740;
  
  return {
    type: CALCULATE_STYLE,
    style: {
      width: windowWidth,
      height: windowHeight,
      layout: layout,
      platform: Platform.OS, 
      headerHeight: headerHeight,
      footerHeight: footerHeight,
      appHeight: appHeight,
    }
  }
}
