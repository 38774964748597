import React, { Component } from 'react';
import { View, Image } from 'react-native';
import PropTypes from 'prop-types';

import RotationAngleBase from '../../../images/rotationAngleBase.png';
import RotationAngleHandle from '../../../images/rotationAngleHandle.png';

class RotationWidget extends Component {

  parentWidth = 140
  width = this.parentWidth * 2;
  parentHeight = 116;
  height = this.parentHeight * 2;
  
  constructor(props) {
    super(props);

    this.state = {
      moving: false
    };
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.ConfiguratorState.torque.rotationAngleDisplay !== this.props.ConfiguratorState.torque.rotationAngleDisplay);
  }

  rotationAngleChangedDegrees(value) {
    this.props.torqueChanged({rotationAngle: value.toString(10) });
  }

  processMove(e, force = false) {
    if (force || this.state.moving) {
      let x = 0;
      let y = 0;

      if (e.nativeEvent) {
        if (e.nativeEvent.locationX >= 0 && e.nativeEvent.locationY >= 0) {
          x = e.nativeEvent.locationX;
          y = e.nativeEvent.locationY;
        }
      } 

      x = x + 10;
      
      // *** hard coding this, must match style
      // const width = 140 * 2;
      // const height = 116 * 2; 
      
      const rad2deg = 180 / Math.PI;
      const centerX = this.width / 2;
      const centerY = this.height / 2;
      const distFromCenterX = centerX - x;
      const distFromCenterY = centerY - y;
      const degrees = Math.atan(distFromCenterX / distFromCenterY) * rad2deg;

      if (distFromCenterX >= 0 && distFromCenterY >= 0) {
        this.rotationAngleChangedDegrees(parseFloat((degrees + 90).toFixed(0)));
      } else if(distFromCenterX <= 0 && distFromCenterY >= 0) {
        this.rotationAngleChangedDegrees(parseFloat((degrees + 90).toFixed(0)));
      } else if(distFromCenterX >= 0 && distFromCenterY <= 0) {
        this.rotationAngleChangedDegrees(parseFloat((degrees + 270).toFixed(0)));
      } else {
        this.rotationAngleChangedDegrees(parseFloat((degrees + 270).toFixed(0)));
      }
    }
  }

  render() {
    let torqueControlsInfoStyles = this.props.stylesheet;
    
    return (
      <View style={torqueControlsInfoStyles.rotationAngleControl}>
        <Image
          style={torqueControlsInfoStyles.rotationAngleControlBase}
          source={RotationAngleBase} />
        <Image
          resizeMode={"contain"}
          style={[torqueControlsInfoStyles.rotationAngleControlHandle,{
            transform: [{rotateZ: `-${this.props.ConfiguratorState.torque.rotationAngle}deg`}]}]}
          source={RotationAngleHandle} />
        <View style={{position: "absolute", top: -(this.height / 2 - this.parentHeight / 2), left: -(this.width / 2 - this.parentWidth / 2), width: this.width, height: this.height, zIndex: 100}}
              onStartShouldSetResponder={(event) => {
                this.setState({moving: true});
                this.processMove(event, true);
                return true;
              }}
              onResponderMove={(event) => {
                this.processMove(event);
              }}
              onResponderRelease={() => {
                this.setState({moving: false});
                return true;
              }}
        />
      </View>
    );
  }
}

RotationWidget.propTypes = {
  ConfiguratorState: PropTypes.object,
  torqueChanged: PropTypes.func
};

export default RotationWidget;
