import React, { Component } from 'react';
import { Pressable } from 'react-native';
import PropTypes from 'prop-types';

class ClickTap extends Component {

  render() {
    return(
      <Pressable {...this.props}
        onPress={this.props.onClick}>
        {this.props.children}
      </Pressable>
    );
  }
}

ClickTap.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func
};

export default ClickTap;
