import React, { Component } from 'react';
import {View, Image, Text, Animated, Platform} from 'react-native';
import PropTypes from 'prop-types';

import loadingStyles from './loadingStyles';
import logo from '../images/reellLogo.png';

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: new Animated.Value(1),
      zIndex: 500
    };
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.active !== this.props.active)
    {
      Animated.timing(this.state.animation, {
        toValue: this.props.active ? 1 : 0,
        duration: 1000,
        useNativeDriver: Platform.OS !== 'web'
      }).start(() => {
        this.setState({zIndex: this.props.active ? 250 : -1})
      });
    }
  }

  render() {
    let opacity = this.state.animation.interpolate({
      inputRange: [0,1],
      outputRange: [0,1]
    });
    return (
      <Animated.View style={[loadingStyles.loading, {
        opacity: this.state.animation,
        zIndex: this.state.zIndex
      }]}>
        <Image resizeMode="contain" style={loadingStyles.logo} source={logo} />
        <Text>Loading ...</Text>
      </Animated.View>
    );
  }
}

Loading.propTypes = {
  active: PropTypes.bool
};

export default Loading;
