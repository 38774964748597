import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  
  languageControl: {
    float: "right",
    position: "relative",
    width: "75%",
    height: 30,
    zIndex: 50,
    backgroundColor: "#212e36",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  
  languageItem: {
    paddingRight: 6,
    cursor: 'pointer',
  },
  
  languageItemText: {
    fontSize: 11,
    lineHeight: 30,
    color: "#F1F1F1",
  },
  
  active: {
    color: "white"
  }

});

export default styles;
