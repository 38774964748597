import React, { Component } from 'react';
import {View, Text, StyleSheet, Image} from 'react-native';
import PropTypes from 'prop-types';
import UIElementText from '../uiElementText';
import ClickTap from '../clickTap';
import ProductWeightButton from './productWeightButton';
import ProductLengthButton from './productLengthButton';
import NumberOfHingesButton from './numberOfHingesButton';
import UnitOfMeasureButton from './unitOfMeasureButton';
import CenterOfGravityButton from './centerOfGravityButton';
import RotationAngleButton from './rotationAngleButton';
import TorqueControlsInfo from './torqueControlsInfo';


import { getResponsiveStylesheet, Layouts } from "../../layout";
import defaultStyles from './torqueStyles';
import tabletStyles from './tabletStyles';
import phabletStyles from "./phabletStyles";
import selectionButtonImage from "../../images/selectionButton.png";

class Torque extends Component {

  render() {
    const styleState = this.props.ConfiguratorState.style;
    const torqueStyles = getResponsiveStylesheet(styleState, {
      default: defaultStyles,
      desktop: defaultStyles,
      tablet: tabletStyles,
      phablet: phabletStyles,
      phone: phabletStyles,
    });
    
    let resultsHeight = this.props.ConfiguratorState.style.layout === Layouts.TABLET ? 238 : 0;
    let height = this.props.ConfiguratorState.style.appHeight - resultsHeight ;

    const torqueStyle = {
      height: height,
      display: (this.props.ConfiguratorState.style.width >= 768 || this.props.ConfiguratorState.calculateTorqueEnabled) ? 'flex' : 'none'
    };

    const disabled = (this.props.ConfiguratorState.productEnabled !== null || this.props.ConfiguratorState.selectionParameterEnabled !== null);

    return (
      <View style={[torqueStyles.torque, torqueStyle]}>
        <View style={[{display: disabled ? "flex" : "none"}, torqueStyles.disabled]}>
          <Image resizeMode="stretch" style={torqueStyles.backgroundImage} source={selectionButtonImage}/>
        </View>
        <View style={torqueStyles.torqueControls}>
          <View style={torqueStyles.torqueControlsButtonColumn}>
            <UnitOfMeasureButton
              styleSheet={torqueStyles}
              ConfiguratorState={this.props.ConfiguratorState}
              torqueChanged={this.props.torqueChanged} />
            <ProductWeightButton
              styleSheet={torqueStyles}
              torqueChanged={this.props.torqueChanged}
              ConfiguratorState={this.props.ConfiguratorState} />
            <ProductLengthButton
              styleSheet={torqueStyles}
              torqueChanged={this.props.torqueChanged}
              ConfiguratorState={this.props.ConfiguratorState} />
            <CenterOfGravityButton
              styleSheet={torqueStyles}
              torqueChanged={this.props.torqueChanged}
              ConfiguratorState={this.props.ConfiguratorState} />
            <RotationAngleButton
              styleSheet={torqueStyles}
              torqueChanged={this.props.torqueChanged}
              ConfiguratorState={this.props.ConfiguratorState} />
            <NumberOfHingesButton
              styleSheet={torqueStyles}
              torqueChanged={this.props.torqueChanged}
              ConfiguratorState={this.props.ConfiguratorState} />
            <ClickTap
              style={torqueStyles.torqueControlsButton}
              onClick={this.props.resetAll}>
              <Image resizeMode="stretch" style={torqueStyles.backgroundImage} source={selectionButtonImage}/>
              <View style={torqueStyles.torqueControlsButtonPadding}>
                <UIElementText
                  style={StyleSheet.flatten(torqueStyles.torqueControlsButtonText)}
                  languageItemKey="reset_calculator"
                  defaultText="RESET CALCULATOR" />
              </View>
            </ClickTap>
            <View style={[torqueStyles.torqueControlsButton, torqueStyles.torqueControlsButtonFiller]}>
              <Image resizeMode="stretch" style={torqueStyles.backgroundImage} source={selectionButtonImage}/>
            </View>
          </View>
          <TorqueControlsInfo {...this.props} />
        </View>
        
        <View>
          <Image resizeMode="stretch" style={torqueStyles.backgroundImage} source={selectionButtonImage}/>
          <View style={torqueStyles.torqueResultsPadding}>
            <UIElementText
              style={StyleSheet.flatten([torqueStyles.torqueResultsText, {textAlign: 'right', textTransform: 'uppercase', marginRight: 10}])}
              languageItemKey="calculated_torque"
              defaultText="Calculated Torque" />
            
            <View style={{width: 45}}>
              <View style={[torqueStyles.torqueResultsValues, {marginBottom: 6}, 
                this.props.ConfiguratorState.torque.unitOfMeasure === 'metric' ? torqueStyles.torqueResultWhite : null]}>
                <Text style={torqueStyles.torqueResultsValuesText}>
                  {this.props.ConfiguratorState.torque.torquenm}
                </Text>
              </View>
              <View style={[torqueStyles.torqueResultsValues,
                this.props.ConfiguratorState.torque.unitOfMeasure === 'english' ? torqueStyles.torqueResultWhite : null]}>
                <Text style={torqueStyles.torqueResultsValuesText}>
                  {this.props.ConfiguratorState.torque.torquelbin}
                </Text>
              </View>
            </View>
            
            <View style={{fontSize: 10, minWidth: 45, padding: 0, flex: 0}}>
              <Text style={{fontSize: 10, textAlign: 'center', fontWeight: "bold", color: "white", marginBottom: 6}}>N-m</Text>
              <Text style={{fontSize: 10, textAlign: 'center', fontWeight: "bold", color: "white"}}>lb-in</Text>
            </View>
  
            <View style={{width: 45}}>
              <View style={[torqueStyles.torqueResultsValues, {marginBottom: 6},
                this.props.ConfiguratorState.torque.unitOfMeasure === 'metric' ? torqueStyles.torqueResultWhite : null]}>
                <Text style={torqueStyles.torqueResultsValuesText}>
                  {this.props.ConfiguratorState.torque.torquePerHingenm}
                </Text>
              </View>
              <View style={[torqueStyles.torqueResultsValues,
                this.props.ConfiguratorState.torque.unitOfMeasure === 'english' ? torqueStyles.torqueResultWhite : null]}>
                <Text style={torqueStyles.torqueResultsValuesText}>
                  {this.props.ConfiguratorState.torque.torquePerHingelbin}
                </Text>
              </View>
            </View>
            
            <UIElementText
              style={StyleSheet.flatten([torqueStyles.torqueResultsText, {marginLeft: 10}])}
              languageItemKey="per_hinge"
              defaultText="Per Hinge" />
          </View>
        </View>

      </View>
    );
  }
}

Torque.propTypes = {
  ConfiguratorState: PropTypes.object,
  torqueChanged: PropTypes.func,
  resetAll: PropTypes.func
};

export default Torque;
