import {StyleSheet} from "react-native";

const styles = StyleSheet.create({

  loading: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "rgba(255,255,255,0.9)",
    zIndex: 500,
    alignItems: "center",
    justifyContent: "center",
    opacity: 1,
  },
   
  logo: {
    paddingBottom: 20,
    width: "90%",
    maxWidth: 300,
    height: 300,
  },

});

export default styles;
