import { RECEIVE_SELECTION_PARAMETERS } from '../actions/selectionParameterActions';

const initialState = {
  data: [],
  receivedAt: null
};

export default function selectionParametersReducer(state = initialState, action: {}) {
  switch (action.type) {
    case RECEIVE_SELECTION_PARAMETERS: {
      return Object.assign({}, state, {
        data: action.selectionParameters,
        receivedAt: action.receivedAt
      });
    }
    default: {
      return state;
    }
  }
}
