import React, { Component } from 'react';
import { View, Image, ScrollView, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import ResultItem from './resultItem';
import UIElementText from '../uiElementText';
import ClickTap from '../clickTap';

import {getResponsiveStylesheet, Layouts} from "../../layout";
import defaultStyles from './resultsStyles';
import tabletStyles from './tablet';
import phabletStyles from "./phablet";

import resultsBackgroundImage from '../../images/resultsBackground.png';
import resultsBackgroundLandscapeImage from '../../images/resultsBackgroundLandscape.png';
import contactMeArrowImage from '../../images/contactMeArrow.png';

class Results extends Component {
  
  shouldComponentUpdate(nextProps, nextState) {
    if (window.innerWidth < 768 && nextProps.ConfiguratorState.calculateTorqueEnabled) return false;
    return nextProps.ConfiguratorState.currentLanguageID !== this.props.ConfiguratorState.currentLanguageID ||
    nextProps.ConfiguratorState.calculateTorqueEnabled !== this.props.ConfiguratorState.calculateTorqueEnabled ||
    nextProps.ConfiguratorState.torque.torquenm !== this.props.ConfiguratorState.torque.torquenm ||
    nextProps.ConfiguratorState.torque.torquePerHingenm !== this.props.ConfiguratorState.torque.torquePerHingenm ||
    nextProps.ConfiguratorState.selectionParameterOptionsChanged !== this.props.ConfiguratorState.selectionParameterOptionsChanged ||
    nextProps.ConfiguratorState.productEnabled !== this.props.ConfiguratorState.productEnabled ||
    nextProps.ConfiguratorState.selectionParameterEnabled !== this.props.ConfiguratorState.selectionParameterEnabled ||
    nextProps.ConfiguratorState.style.width !== this.props.ConfiguratorState.style.width ||
    nextProps.ConfiguratorState.style.height !== this.props.ConfiguratorState.style.height ||
    nextProps.ConfiguratorState.style.layout !== this.props.ConfiguratorState.style.layout;
  }

  render() {
    const styleState = this.props.ConfiguratorState.style;
    const resultsStyles = getResponsiveStylesheet(styleState, {
      default: defaultStyles,
      desktop: defaultStyles,
      tablet: tabletStyles,
      phablet: phabletStyles,
      phone: phabletStyles,
    });
    
    let products = [];
    if (this.props.ConfiguratorState.filteredProducts.length === 0) {
      products = (
        <View style={resultsStyles.resultItem}>
          <UIElementText
            style={resultsStyles.resultItemText}
            languageItemKey="no_results_message"
            defaultText="No items meet your selection criteria." />
        </View>
      );
    } else {
      for (const product of this.props.ConfiguratorState.filteredProducts) {
        products.push(
          <ResultItem
            key={product.id}
            active={this.props.ConfiguratorState.productEnabled === product.id}
            product={product}
            stylesheet={resultsStyles}
            ConfiguratorState={this.props.ConfiguratorState}
            productClicked={this.props.productClicked} />
        );
      }
    }

    return (
      <View style={[resultsStyles.results]} >
        <View style={resultsStyles.scrollContainer}>
          <Image resizeMode="stretch" style={resultsStyles.backgroundImage} 
                 source={this.props.ConfiguratorState.style.layout === Layouts.TABLET ? 
                   resultsBackgroundLandscapeImage : resultsBackgroundImage}/>
          <ScrollView style={resultsStyles.scrollable}>
            <View style={resultsStyles.scrollableContentWrapper}>
              {products}
            </View>
          </ScrollView>
        </View>
        <ClickTap
          style={resultsStyles.contactButton}
          onClick={(e) => {e.preventDefault(); this.props.contactMeNowClicked();}}>
          <Image resizeMode="stretch" style={resultsStyles.backgroundImage} source={contactMeArrowImage}/>
          <View style={resultsStyles.contactButtonPadding}>
            <UIElementText
              style={StyleSheet.flatten(resultsStyles.contactButtonText)}
              languageItemKey="contact_me_now"
              defaultText="Contact Me Now!" />
          </View>
        </ClickTap>
      </View>
    );
  }
}

Results.propTypes = {
  ConfiguratorState: PropTypes.object,
  productClicked: PropTypes.func,
  contactMeNowClicked: PropTypes.func
};

export default Results;
