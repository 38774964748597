import React, { Component } from 'react';
import {View, Image, Text, TextInput, StyleSheet, Pressable} from 'react-native';
import {Picker} from '@react-native-picker/picker';
import PropTypes from 'prop-types';
import UIElementText from './uiElementText';
import ClickTap from './clickTap';
import {
  CONTACT_ME_NOW_FORM_SUBMITTED,
  CONTACT_ME_NOW_FORM_SUCCESS,
  CONTACT_ME_NOW_FORM_FAILURE
} from '../actions/configuratorStateActions';

import contactMeNowStyles from './contactMeNowStyles';
import closeCheckbox from '../images/closeCheckbox.png';
// import {Picker} from "react-native-web";
// import PickerItem from "react-native-web/dist/exports/Picker/PickerItem";

class ContactMeNow extends Component {

  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
    // this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  // componentDidMount() {
  //   document.addEventListener('mouseup', this.clicked);
  //   document.addEventListener('touchend', this.clicked);
  //   if (process.env.PHONEGAP) {
  //     document.addEventListener('keyup', this.handleKeyPress);
  //   }
  // }

  // componentWillUnmount() {
  //   if (process.env.PHONEGAP) {
  //     document.removeEventListener('keyup', this.handleKeyPress);
  //   }
  // }

  clicked(e) {
    // if (e.target.id === 'contactMeNow') {
    //   e.preventDefault();
    //   e.stopPropagation();
      this.close.bind(this)();
    // }
  }

  close() {
    // document.removeEventListener('mouseup', this.clicked);
    // document.removeEventListener('touchend', this.clicked);
    this.props.contactMeNowClicked();
  }

  formChanged(e, inputName) {
    if (inputName === "region")
    {
      this.props.contactMeNowFormChanged({
        [inputName]: e
      });
    } else {
      e.preventDefault();
      this.props.contactMeNowFormChanged({
        [inputName]: e.target.value
      });
    }
  }

  formSubmitted(e) {
    e.preventDefault();
    this.props.postContactForm();
  }

  validateForm() {
    if (this.props.ConfiguratorState.loggedInUser.firstName === '' ||
        this.props.ConfiguratorState.loggedInUser.lastName === '' ||
        this.props.ConfiguratorState.loggedInUser.company === '' ||
        this.props.ConfiguratorState.loggedInUser.phone === '' ||
        this.props.ConfiguratorState.loggedInUser.email === '') {
      return false;
    }
    return true;
  }

  // handleKeyPress(e) {
  //   if (process.env.PHONEGAP) {
  //     const evt = e || window.event;
  //     if (evt.keyCode === 13) {
  //       Keyboard.hide();
  //     }
  //   }
  // }

  render() {
    let submitDisabled = !this.validateForm() || this.props.ConfiguratorState.contactMeNowFormState !== null;
    let submitMessage = '';
    let submitMessageKey = '';
    let hideClass = '';
    switch (this.props.ConfiguratorState.contactMeNowFormState) {
      case CONTACT_ME_NOW_FORM_SUBMITTED: {
        submitMessage = 'Please wait while we process your request...';
        submitMessageKey = 'contact_form_submitted';
        break;
      }
      case CONTACT_ME_NOW_FORM_SUCCESS: {
        submitMessage = 'Thank you for contacting Reell';
        submitMessageKey = 'contact_form_success';
        hideClass = contactMeNowStyles.hide;
        break;
      }
      case CONTACT_ME_NOW_FORM_FAILURE: {
        submitMessage = 'An error has occurred, please try again';
        submitMessageKey = 'contact_form_failure';
        break;
      }
      default: {
        submitMessage = '';
        submitMessageKey = '_blank';
      }
    }

    return (
      <View id="contactMeNow" style={contactMeNowStyles.contactMeNow}>
        <View style={contactMeNowStyles.contactMeNowContent}>
          <ClickTap
            style={contactMeNowStyles.exitButton}
            onClick={this.close.bind(this)}>
            <Image style={contactMeNowStyles.exitButtonImage} source={closeCheckbox} />
            <UIElementText
              style={StyleSheet.flatten(contactMeNowStyles.exitButtonText)}
              languageItemKey="close"
              defaultText="CLOSE" />
          </ClickTap>

          <View style={[contactMeNowStyles.contactMeNowInstructions, hideClass]}>
            <UIElementText
              languageItemKey="contact_form_instructions"
              defaultText="Please fill in all of the following fields. A Reell Sales Representative will contact you shortly." />
          </View>
          
          <View style={hideClass}>
            <View style={contactMeNowStyles.row}>
              <View style={{flexDirection: "row"}}>
                <UIElementText
                  style={StyleSheet.flatten(contactMeNowStyles.label)}
                  languageItemKey="first_name"
                  defaultText="First Name" />
                <Text style={contactMeNowStyles.required}>*</Text>
              </View>
              <TextInput type="text" name="name" style={contactMeNowStyles.input}
                value={this.props.ConfiguratorState.loggedInUser.firstName}
                onChange={(e) => this.formChanged(e, "firstName")} />
            </View>
            
            <View style={contactMeNowStyles.row}>
              <View style={{flexDirection: "row"}}>
                <UIElementText
                  style={StyleSheet.flatten(contactMeNowStyles.label)}
                  languageItemKey="last_name"
                  defaultText="Last Name" />
                <Text style={contactMeNowStyles.required}>*</Text>
              </View>
              <TextInput type="text" name="lastname" style={contactMeNowStyles.input}
                value={this.props.ConfiguratorState.loggedInUser.lastName}
                onChange={(e) => this.formChanged(e, "lastName")} />
            </View>
            
            <View style={contactMeNowStyles.row}>
              <View style={{flexDirection: "row"}}>
                <UIElementText
                  style={StyleSheet.flatten(contactMeNowStyles.label)}
                  languageItemKey="company"
                  defaultText="Company" />
                <Text style={contactMeNowStyles.required}>*</Text>
              </View>
              <TextInput type="text" name="company" style={contactMeNowStyles.input}
                value={this.props.ConfiguratorState.loggedInUser.company}
                onChange={(e) => this.formChanged(e, "company")} />
            </View>
            
            <View style={contactMeNowStyles.row}>
              <View style={{flexDirection: "row"}}>
                <UIElementText
                  style={StyleSheet.flatten(contactMeNowStyles.label)}
                  languageItemKey="phone_number"
                  defaultText="Phone Number" />
                <Text style={contactMeNowStyles.required}>*</Text>
              </View>
              <TextInput type="text" name="phone" style={contactMeNowStyles.input}
                value={this.props.ConfiguratorState.loggedInUser.phone}
                onChange={(e) => this.formChanged(e, "phone")} />
            </View>

            <View style={contactMeNowStyles.row}>
              <View style={{flexDirection: "row"}}>
                <UIElementText
                  style={StyleSheet.flatten(contactMeNowStyles.label)}
                  languageItemKey="email_address"
                  defaultText="Email Address" />
                <Text style={contactMeNowStyles.required}>*</Text>
              </View>
              <TextInput type="text" name="email" style={contactMeNowStyles.input}
                value={this.props.ConfiguratorState.loggedInUser.email}
                onChange={(e) => this.formChanged(e, "email")} />
            </View>

            <View style={contactMeNowStyles.row}>
              <UIElementText
                style={StyleSheet.flatten(contactMeNowStyles.label)}
                languageItemKey="region"
                defaultText="Region" />
              <View style={contactMeNowStyles.pickerWrapper} >
                <Picker
                  style={contactMeNowStyles.picker}
                  itemStyle={contactMeNowStyles.pickerItem}
                  selectedValue={this.props.ConfiguratorState.loggedInUser.region}
                  onValueChange={(value) => this.formChanged(value, "region")} >
                  <Picker.Item style={{height: 15}} label="Americas" value="region_americas"/>
                  <Picker.Item style={{height: 15}} label={"EMEA"} value={"region_emea"}/>
                  <Picker.Item style={{height: 15}} label={"China"} value={"region_china"}/>
                  <Picker.Item style={{height: 15}} label={"Japan"} value={"region_japan"}/>
                  <Picker.Item style={{height: 15}} label={"SE Asia/Oceania"} value={"region_asia"}/>
                  <Picker.Item style={{height: 15}} label={"Other"} value={"region_other"}/>
                </Picker>
              </View>
            </View>

            <View style={contactMeNowStyles.row}>
              <UIElementText
                style={StyleSheet.flatten(contactMeNowStyles.label)}
                languageItemKey="comments"
                defaultText="Comments" />
              <TextInput name="comments"
                style={contactMeNowStyles.input}
                multiline={true}
                numberOfLines={3}
                value={this.props.ConfiguratorState.loggedInUser.comments}
                onChange={(e) => this.formChanged(e, "comments")} />
            </View>
          </View>

          <View style={[contactMeNowStyles.row, hideClass]}>
            <Pressable title={"Submit"} 
                       style={[contactMeNowStyles.submit, submitDisabled ? contactMeNowStyles.submitDisabled : null]}
                       disabled={submitDisabled} 
                       onPress={this.formSubmitted.bind(this)}>
              <Text style={[contactMeNowStyles.submitText, submitDisabled ? contactMeNowStyles.submitTextDisabled : null]}>Submit</Text>
            </Pressable>
          </View>

          <View style={contactMeNowStyles.row}>
            <UIElementText
              style={StyleSheet.flatten(contactMeNowStyles.label)}
              languageItemKey={submitMessageKey}
              defaultText={submitMessage} />
          </View>
        </View>
      </View>
    );
  }
}

ContactMeNow.propTypes = {
  ConfiguratorState: PropTypes.object,
  contactMeNowClicked: PropTypes.func,
  contactMeNowFormChanged: PropTypes.func,
  postContactForm: PropTypes.func
};

export default ContactMeNow;
