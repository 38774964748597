import {Platform, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  
  torque: {
    flex: 2,
    borderTopWidth: 4,
    borderTopColor: "red",
    marginLeft: 10,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 9,
    height: "100%",
    minHeight: 500,
  },
  
  disabled: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    opacity: 0.7,
  },
  
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  
  torqueControls: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  torqueControlsButtonColumn: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "bold",
  },
  torqueControlsButton: {
    width: 140,
    minHeight: 35,
    position: "relative",
  },
  torqueControlsButtonPadding: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
  },
  torqueControlsButtonText: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#F1F1F1",
    marginBottom: 5,
    textTransform: "uppercase",
  },
  torqueControlsButtonTextSmall: {
    fontSize: 8,
    fontWeight: "bold",
    color: "#F1F1F1",
  },
  torqueControlsButtonFiller: {
    flex: 1,
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 0,
    cursor: "default",
  },
  torqueControlsButtonValue: {
    borderRadius: 3,
    borderWidth: 0,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: "#a3baf6",
    color: "black",
    fontWeight: "bold",
    width: "80%",
    marginRight: 5,
    maxHeight: 25,
  },
  torqueControlsButtonValueDisabled: {
    color: "rgb(84, 84, 84)",
    backgroundColor: "#6177b3",
  },

  unitOfMeasureSelectBox: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginRight: 5,
    marginBottom: 5,
  },
  unitOfMeasureCheckBox: {
    width: 12,
    height: 12,
    marginRight: 2,
  },
  
  torqueResultsPadding: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  torqueResultsText: {
    fontSize: 10, 
    fontWeight: "bold", 
    color: "white", 
    textTransform: 'uppercase',
  },
  torqueResultsValues: {
    borderRadius: 3,
    paddingTop: 3,
    paddingBottom: 2,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: "#a3baf6",
    width: "100%",
    minHeight: 15,
  },
  torqueResultsValuesText: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
  },
  torqueResultWhite: {
    backgroundColor: "white",
  }

});

export default styles;