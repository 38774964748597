import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  torque: {
    flex: 2,
    borderTopWidth: 4,
    borderTopColor: "red",
    borderBottomWidth: 4,
    borderBottomColor: "black",
    marginLeft: 10,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 9,
  },
  
});

export default styles;