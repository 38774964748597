import React, { Component } from 'react';
import { View, Image } from 'react-native';
import PropTypes from 'prop-types';

import WeightDistributionControlBase from '../../../images/weightDistribBase.png';
import WeightDistributionControlHandle from '../../../images/weightDistribHandle.png';

class CenterOfGravityWidget extends Component {

  constructor(props) {
    super(props);

    this.state = {
      moving: false
    };
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.ConfiguratorState.torque.unitOfMeasure !== this.props.ConfiguratorState.torque.unitOfMeasure ||
      nextProps.ConfiguratorState.torque.productWeightDisplay !== this.props.ConfiguratorState.torque.productWeightDisplay ||
      nextProps.ConfiguratorState.torque.lengthDisplay !== this.props.ConfiguratorState.torque.lengthDisplay ||
      nextProps.ConfiguratorState.torque.distanceToCenterOfGravityDisplay !== this.props.ConfiguratorState.torque.distanceToCenterOfGravityDisplay ||
      nextProps.ConfiguratorState.torque.centerOfGravityEvenlyDistributed !== this.props.ConfiguratorState.torque.centerOfGravityEvenlyDistributed);
  }

  processMove(e, force = false) {
    const length = this.props.ConfiguratorState.torque.length;
    if (length === '' || this.props.ConfiguratorState.torque.centerOfGravityEvenlyDistributed) {
      return;
    }

    if (force || this.state.moving) {
      let x = 0;
      if (e.nativeEvent) {
        if (e.nativeEvent.locationX >= 0) {
          x = e.nativeEvent.locationX;
        }
      } 
      const maxX = 180; // *** hard coding this, must match style width
      const newLength = (x / maxX * this.props.ConfiguratorState.torque.length).toFixed(2);
      this.props.torqueChanged({distanceToCenterOfGravity: newLength.toString(10) });
    }
  }

  render() {
    let torqueControlsInfoStyles = this.props.stylesheet;
    
    let left = '44%';
    let arrowBarWidth = 70;
    const length = this.props.ConfiguratorState.torque.length;
    const cog = this.props.ConfiguratorState.torque.distanceToCenterOfGravity;
    if (length !== '' && cog !== '') {
      let leftValue = Math.min(94, (cog / length) * 100 - 6);
      leftValue = Math.max(-6, leftValue);
      left = `${leftValue}%`;

      arrowBarWidth = (cog / length) * 177 - 17;
      arrowBarWidth = Math.max(0, arrowBarWidth);
      arrowBarWidth = Math.min(160, arrowBarWidth);
    }
    return (
      <View style={torqueControlsInfoStyles.weightDistributionControlDiv}>
        <View style={[torqueControlsInfoStyles.weightDistributionControl, 
            {opacity: this.props.ConfiguratorState.torque.centerOfGravityEvenlyDistributed ? 0.3 : 1}]}>
          <Image
            style={torqueControlsInfoStyles.weightDistributionControlBase}
            source={WeightDistributionControlBase} />
          <Image
            style={[torqueControlsInfoStyles.weightDistributionControlHandle, {left: left}]}
            source={WeightDistributionControlHandle} />
          <View style={[torqueControlsInfoStyles.WeightDistributionControlArrowBar, {width: arrowBarWidth}]}/>
        </View>
        <View style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 100}}
              onStartShouldSetResponder={(event) => {
                this.setState({moving: true});
                this.processMove(event, true);
                return true;
              }}
              onResponderMove={(event) => {
                this.processMove(event);
              }}
              onResponderRelease={() => {
                this.setState({moving: false});
                return true;
              }}
        />
      </View>
    );
  }
}

CenterOfGravityWidget.propTypes = {
  ConfiguratorState: PropTypes.object,
  torqueChanged: PropTypes.func
};

export default CenterOfGravityWidget;
