import React, { Component } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import UIElementText from '../uiElementText';
import SelectionParameterOptionItem from './selectionParameterOptionItem';
import ClickTap from '../clickTap';

import closeCheckbox from '../../images/closeCheckbox.png';
import selectionOptionsBackground from '../../images/selectionOptionsBackground.png';

class SelectionParameterOptions extends Component {
  
  selectionParameterOptionClicked(selectionParameterOptionID) {
    this.props.selectionParameterOptionClicked(selectionParameterOptionID);
  }

  isSelected(selectionParameterOptionID) {
    const selectedParameterID = this.props.ConfiguratorState.selectionParameterOptionsEnabled.findIndex((sp) => {
      return sp.options.indexOf(selectionParameterOptionID) >= 0;
    });
    return selectedParameterID >= 0;
  }

  render() {
    const selectionParametersStyles = this.props.Stylesheet;
    
    if (this.props.optionsActive === false) {
      return (
        <View style={[selectionParametersStyles.selectionParameterOptionsWrapper, selectionParametersStyles.selectionParameterOptionsWrapperHidden]}>
          <View style={[selectionParametersStyles.selectionParameterOptions, selectionParametersStyles.selectionParameterOptionsWrapperHidden]}/>
        </View>
      );
    }
    const selectionParameterOptions = [];
    for (const selectionParameterOption of this.props.selectionParameterOptions) {
      selectionParameterOptions.push(
        <SelectionParameterOptionItem
          key={selectionParameterOption.id}
          selectionParameterOption={selectionParameterOption}
          selectionParameterOptionClicked={this.selectionParameterOptionClicked.bind(this)}
          selected={this.isSelected(selectionParameterOption.id)}
          Stylesheet={this.props.Stylesheet}
        />
      );
    }
    return (
      <View style={[selectionParametersStyles.selectionParameterOptionsWrapper, selectionParametersStyles.selectionParameterOptionsWrapperVisible]}>
        <View style={selectionParametersStyles.selectionParameterOptions}>
          <Image 
            resizeMode="stretch" 
            style={selectionParametersStyles.backgroundImage}
            source={selectionOptionsBackground}/>
          <View style={selectionParametersStyles.selectionParameterOptionsPadding}>
            {selectionParameterOptions}
          </View>
          <ClickTap
            style={selectionParametersStyles.closeButton}
            onClick={this.props.close}>
            <Image style={selectionParametersStyles.closeButtonImage} source={closeCheckbox} />
            <UIElementText
              style={StyleSheet.flatten(selectionParametersStyles.closeButtonText)}
              languageItemKey="close"
              defaultText="CLOSE" />
          </ClickTap>
        </View>
      </View>
    );
  }
}

SelectionParameterOptions.propTypes = {
  optionsActive: PropTypes.bool,
  selectionParameterID: PropTypes.number,
  selectionParameterOptions: PropTypes.array,
  ConfiguratorState: PropTypes.object,
  Stylesheet: PropTypes.object,
  selectionParameterOptionClicked: PropTypes.func,
  close: PropTypes.func
};

export default SelectionParameterOptions;
