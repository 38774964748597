import { RECEIVE_LANGUAGES } from '../actions/languageActions';

const initialState = {
  data: [],
  receivedAt: null
};

export default function languagesReducer(state = initialState, action: {}) {
  switch (action.type) {
    case RECEIVE_LANGUAGES: {
      return Object.assign({}, state, {
        data: action.languages,
        receivedAt: action.receivedAt
      });
    }
    default: {
      return state;
    }
  }
}
