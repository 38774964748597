import { StyleSheet, Platform } from 'react-native';

const styles = StyleSheet.create({

  contactMeNow: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 20,
  },
  
  hide: {
    display: "none",
  },

  contactMeNowContent: {
    maxWidth: 400,
    alignItems: "stretch",
    backgroundColor: "white",
    borderRadius: 20,
    borderWidth: 3,
    borderColor: "black",
    position: "relative",
    color: "#4d4d4d",
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
  
  contactMeNowInstructions: {
    marginBottom: 10,
  },
  

  row: {
    alignItems: "center",
    marginBottom: 10,
  },
  label: {
    fontSize: 10,
    textTransform: "uppercase",
  },
  required: {
    fontSize: 10,
    color: "red",
    paddingLeft: 2,
  },
  input: {
    fontSize: 10,
    padding: 5,
    width: "100%",
    borderWidth: 1,
    borderRadius: 4,
  },
  pickerWrapper: {
    height: Platform.OS === 'ios' ? 100 : 25,
    width: "100%",
    borderWidth: Platform.OS === 'ios' ? 1 : 0,
    borderRadius: 4,
  },
  picker: {
    height: "100%",
    width: "100%",
    borderRadius: 4,
  },
  pickerItem: {
    fontSize: 12,
    height: 100,
  },

  submit: {
    marginTop: 10,
    borderWidth: 2,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: "rgb(239, 239, 239)",
    borderColor: "rgba(16, 16, 16, 1)",
    borderRadius: 3,
  },
  submitDisabled: {
    backgroundColor: "rgb(239, 239, 239)",
    borderColor: "rgba(16, 16, 16, 0.3)",
  },
  submitText: {
    fontSize: 12,
  },
  submitTextDisabled: {
    color: "rgba(16, 16, 16, 0.3)",
  },

  exitButton: {
    position: "absolute",
    top: 10,
    right: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  exitButtonImage: {
    height: 8,
    width: 8,
    marginRight: 5,
  },
  exitButtonText: {
    fontSize: 10,
    color: "black",
  }



});

export default styles;