import React, { Component } from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class LanguageItemText extends Component {

  parseHTML(inputText) {
    let outputText = inputText;
    outputText = outputText.replace(/([\s\S]*?)<p[\s\S]*?>/g, '$1');
    outputText = outputText.replace(/<\/p>/g,'');
    outputText = outputText.replace(/&#39;/g,'\'');
    outputText = outputText.replace(/&quot;/g,'"');
    outputText = outputText.replace(/&nbsp;/g,'\xa0');
    outputText = outputText.replace(/&reg;/g,'®');
    outputText = outputText.replace(/&rsquo;/g,'\x80');
    outputText = outputText.replace(/&deg;/g,'\xb0');
    outputText = outputText.replace(/([\s\S]*?)<div[\s\S]*?>/g, '$1');
    outputText = outputText.replace(/<\/div>/g,'');
    outputText = outputText.replace(/&trade;/g,'™');
    outputText = outputText.replace(//g, '\'');
    outputText = outputText.replace(/([\s\S]*?)<span[\s\S]*?>/g, '$1');
    outputText = outputText.replace(/<\/span>/g,'');
    outputText = outputText.replace(/&eacute;/g, '\xe9');
    outputText = outputText.replace(/&egrave;/g, '\xe8');
    outputText = outputText.replace(/&ecirc;/g, '\xea');
    outputText = outputText.replace(/&aacute;/g, '\xe1');
    outputText = outputText.replace(/&agrave;/g, '\xe0');
    outputText = outputText.replace(/&icirc;/g, '\xee');
    outputText = outputText.replace(/&ocirc;/g, '\xf4');
    outputText = outputText.replace(/&szlig;/g, '\xdf');
    outputText = outputText.replace(/&auml;/g, '\xe4');
    outputText = outputText.replace(/&uuml;/g, '\xfc');
    outputText = outputText.replace(/&Uuml;/g, '\xdc');
    outputText = outputText.replace(/&ouml;/g, '\xf6');
    outputText = outputText.replace(/&Ouml;/g, '\xd6');
    outputText = outputText.replace(/&ldquo;/g, '\x201c');
    outputText = outputText.replace(/&rdquo;/g, '\x201d');
    outputText = outputText.replace(/&iacute;/g, '\xed');
    outputText = outputText.replace(/&oacute;/g, '\xf3');
    outputText = outputText.replace(/&ntilde;/g, '\xf1');
    outputText = outputText.replace(/&uacute;/g, '\xfa');

    return outputText.trim();
  }
  
  render() {
    let outputText = this.props.defaultText;
    let languageItem = this.props.languageItems.find((li) => {
      return li.language_id === this.props.currentLanguageID && li.key === this.props.languageItemKey;
    });
    if (languageItem) {
      outputText = languageItem.value;
    } else {
      const englishLanguage = this.props.Languages.data.find(language => { return language.name.toUpperCase() === 'ENGLISH'; });
      if (englishLanguage) {
        languageItem = this.props.languageItems.find((li) => {
          return li.language_id === englishLanguage.id && li.key === this.props.languageItemKey;
        });
        if (languageItem) {
          outputText = languageItem.value;
        }
      }
    }
    outputText = this.parseHTML(outputText);
    return (
      <View
        className={`${this.props.className}`}
        style={{display: outputText === '' ? 'none' : 'flex'}}
        dangerouslySetInnerHTML={{__html: outputText}}>
        <Text style={this.props.style}>{outputText}</Text>
      </View>
    );
  }
}

LanguageItemText.propTypes = {
  className: PropTypes.string,
  languageItemKey: PropTypes.string,
  defaultText: PropTypes.string,
  languageItems: PropTypes.array,
  currentLanguageID: PropTypes.number,
  Languages: PropTypes.object
};

function mapStateToProps(state) {
  return {
    Languages: state.Languages,
    currentLanguageID: state.ConfiguratorState.currentLanguageID
  };
}

export default connect(mapStateToProps)(LanguageItemText);
