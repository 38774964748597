import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  body: {
    backgroundColor: "#212e36",
    margin: 0,
    width: "100%",
    height: "100%",
    minHeight: 700,
    fontSize: "14pt",
    color: "#F1F1F1",
    alignItems: "center",
  },
  wrapper: {
    width: "100%",
    maxWidth: 1366,
    minWidth: 620,
    backgroundColor: "white",
    position: "relative"
  },
  app: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    backgroundColor: "#212e36",
    minHeight: 500,
    maxHeight: 829,
  },
  
  footer: {
    width: "100%",
    backgroundColor: "rgb(33, 46, 54)", 
    alignItems: "center", 
    justifyContent: "center",
  },
  footerText: {
    color: "white", 
    paddingHorizontal: 30,
    fontSize: 10,
    maxWidth: 700,
  },
});

export default styles;