import getJson from './getJson';

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export function requestProducts() {
  return {
    type: REQUEST_PRODUCTS
  };
}

export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export function receiveProducts(params) {
  return {
    type: RECEIVE_PRODUCTS,
    products: params,
    receivedAt: Date.now()
  };
}

export function fetchProducts() {
  return (dispatch) => {
    dispatch(requestProducts());
    return getJson('products', (json) => {
      dispatch(receiveProducts(json));
    });
  };
}
