import { StyleSheet, Platform } from 'react-native';

const styles = StyleSheet.create({

  results: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderTopWidth: 0,
    margin: 0,
    padding: 0,
    zIndex: 100,
    width: "100%",
    height: 238,
  },

  scrollContainer: {
    flex: 1,
    margin: 0,
    position: "relative",
    height: "100%",
  },

  scrollable: {
    display: "flex",
    position: "relative",
    width: "93%",
    height: "95%",
    marginTop: 20,
    marginBottom: 20,
    marginLeft: "3.4%",
    marginRight: "3.4%",
  },
  scrollableContentWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
  },

  resultItem: {
    flex: 1,
    flexBasis: "33%",
    flexGrow: 1,
    flexShrink: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "black",
    marginLeft: 0,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    position: "relative",
    minHeight: 60,
    height: 60,
    borderBottomWidth: 0,
  },
  
  resultItemActive: {
    borderRadius: 5,
    borderTopWidth: 3,
    borderBottomWidth: 3,
    borderLeftWidth: 3,
    borderRightWidth: 3,
  },

  resultItemTextWrapper: {
    flex: 1,
    marginLeft: 10,
  },

  activeImage: {
    display: "none",
  },

  contactButton: {
    flexBasis: "30%",
    height: 75,
    minHeight: 70,
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },

});

export default styles;