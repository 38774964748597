import getJson from './getJson';

export const REQUEST_LANGUAGES = 'REQUEST_LANGUAGES';
export function requestLanguages() {
  return {
    type: REQUEST_LANGUAGES
  };
}

export const RECEIVE_LANGUAGES = 'RECEIVE_LANGUAGES';
export function receiveLanguages(params) {
  return {
    type: RECEIVE_LANGUAGES,
    languages: params,
    receivedAt: Date.now()
  };
}

export function fetchLanguages() {
  return (dispatch) => {
    dispatch(requestLanguages());
    return getJson('languages', (json) => {
      dispatch(receiveLanguages(json));
    });
  };
}
