import { RECEIVE_UIELEMENTS } from '../actions/uiElementActions';

const initialState = {
  data: [],
  receivedAt: null
};

export default function uiElementsReducer(state = initialState, action: {}) {
  switch (action.type) {
    case RECEIVE_UIELEMENTS: {
      return Object.assign({}, state, {
        data: action.uiElements,
        receivedAt: action.receivedAt
      });
    }
    default: {
      return state;
    }
  }
}
