import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  torqueControlsInfo: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: 20,
    zIndex: 10,
  },

  instructions: {
    paddingLeft: 5,
    flex: 1,
  },

  widgetWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  widgetWrapperLabel: {
    paddingBottom: 10,
  },

});

export default styles;