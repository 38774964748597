import React, { Component } from 'react';
import { Linking } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Pressable} from "react-native";

class LanguageItemLink extends Component {

  clicked(e) {
    // if (process.env.PHONEGAP) {
    //   e.preventDefault();
    //   window.open(e.target.getAttribute('href'), device.platform === "Android" ? '_system' : '_blank');
    // }
    Linking.openURL(this.getLink()).catch(err => console.error("Couldn't load page", err));
  }
  
  getLink() {
    let outputText = this.props.defaultText;
    let languageItem = this.props.languageItems.find((li) => {
      return li.language_id === this.props.currentLanguageID && li.key === this.props.languageItemKey;
    });
    if (languageItem) {
      outputText = languageItem.value;
    } else {
      const englishLanguage = this.props.Languages.data.find(language => { return language.name.toUpperCase() === 'ENGLISH'; });
      if (englishLanguage) {
        languageItem = this.props.languageItems.find((li) => {
          return li.language_id === englishLanguage.id && li.key === this.props.languageItemKey;
        });
        if (languageItem) {
          outputText = languageItem.value;
        }
      }
    }
    return outputText;
  }

  render() {
    let outputText = this.getLink();
    
    return (
      <Pressable
        style={this.props.style}
        className={this.props.className}
        onPress={this.clicked.bind(this)}>
        {this.props.children}
      </Pressable>
    );
  }
}

LanguageItemLink.propTypes = {
  target: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  languageItemKey: PropTypes.string,
  defaultText: PropTypes.string,
  languageItems: PropTypes.array,
  currentLanguageID: PropTypes.number,
  Languages: PropTypes.object,
  style: PropTypes.object
};

function mapStateToProps(state) {
  return {
    Languages: state.Languages,
    currentLanguageID: state.ConfiguratorState.currentLanguageID
  };
}

export default connect(mapStateToProps)(LanguageItemLink);
