import { RECEIVE_PRODUCTS } from '../actions/productActions';

const initialState = {
  data: [],
  receivedAt: null
};

export default function selectionParametersReducer(state = initialState, action: {}) {
  switch (action.type) {
    case RECEIVE_PRODUCTS: {
      return Object.assign({}, state, {
        data: action.products,
        receivedAt: action.receivedAt
      });
    }
    default: {
      return state;
    }
  }
}
