import { StyleSheet, Platform } from 'react-native';

const styles = StyleSheet.create({

  parameters: {
    flex: 1,
    backgroundColor: "white",
    borderTopWidth: 5,
    borderTopColor: "red",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
  },

  backgroundImage : {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  
  selectionParameter: {
    flex: 1,
    position: "relative",
    textTransform: "uppercase",
    minWidth: 0,
  },
  selectionParameterPadding: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
  
  selectionParameterText: {
    fontSize: 12,
    color: "#F1F1F1",
    fontWeight: "bold",
  },
  
  selectionParameterActive: {
    zIndex: 10,
  },
  
  selectionParameterActiveAfter: {
    position: "absolute",
    top: 0,
    right: -40,
    width: 65,
    height: "100%",
    zIndex: 90,
  },
  
  selectionParameterActiveBefore: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 100,
    backgroundColor: "rgba(0,0,0,.1)",
  },

  selectionParameterOptionsDisplay: {
    flexWrap: "wrap",
    flexDirection: "row",
    marginTop: 5,
    zIndex: 100,
    position: "relative",
  },
  selectionParameterOptionsDisplayImage: {
    maxHeight: 30,
    height: 30,
    width: 30,
    marginRight: 5,
    zIndex: 100,
  },

  selectionParameterOptionsWrapper: {
    position: "absolute",
    left: "120%",
    top: 0,
    minWidth: 370,
    minHeight: "40%",
    height: "100%",
    opacity: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  selectionParameterOptionsWrapperVisible: {
    opacity: 1,
    zIndex: 10,
  },
  selectionParameterOptionsWrapperHidden: {
    opacity: 0,
    zIndex: -1,
  },

  selectionParameterOptionsPadding: {
    paddingTop: "18%",
    paddingBottom: "14%",
    paddingLeft: "9%",
    paddingRight: 0,
  },
  selectionParameterOptions: {
    position: "relative",
    minHeight: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  selectionParameterOptionItem: {
    marginLeft: 20,
    marginRight: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  selectionParameterOptionItemCheckBox: {
    width: 20,
    height: 20,
  },

  selectionParameterOptionItemIcon: {
    // display: "inline-block",
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 25,
    marginRight: 25,
    height: 60,
    width: 60,
  },
  selectionParameterOptionItemIconImage: {
    height: "100%",
  },

  selectionParameterOptionItemText: {
    color: "black",
    fontWeight: "bold",
  },
  
  closeButton: {
    position: "absolute",
    right: "17%",
    top: "8.5%",
    width: "25%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  
  closeButtonImage: {
    height: 13,
    width: 13,
    marginRight: 7,
  },
  
  closeButtonText: {
    textAlign: "center",
    fontSize: 9,
    color: "black",
    fontWeight: "bold",
  },

});

export default styles;
