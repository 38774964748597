import React from 'react';
import { Platform } from 'react-native';
import * as ScreenOrientation from 'expo-screen-orientation';
import store from './src/store/store';
import Root from './src/containers/Root'
import { fetchSelectionParameters } from './src/actions/selectionParameterActions';
import { fetchProducts } from './src/actions/productActions';
import { fetchLanguages } from './src/actions/languageActions';
import { initializeLanguage, initializeSelectionParameters, loadComplete } from './src/actions/configuratorStateActions';
import { fetchUIElements } from './src/actions/uiElementActions';
import { calculateStyle } from "./src/actions/configuratorStateActions";

const dataStore = store();

export default function App() {
  return (
    <Root store={dataStore} />
  );
}

// populate data
const promises = [
  Platform.OS !== 'web' ? ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP) : null,
  dataStore.dispatch(calculateStyle()),
  dataStore.dispatch(fetchProducts()),
  dataStore.dispatch(fetchSelectionParameters()),
  dataStore.dispatch(fetchUIElements()),
  dataStore.dispatch(fetchLanguages())
];
Promise.all(promises).then(() => {
  dataStore.dispatch(initializeSelectionParameters(dataStore.getState().SelectionParameters.data));
  dataStore.dispatch(initializeLanguage(dataStore.getState().Languages.data));
  dataStore.dispatch(loadComplete());
});
