import getJson from './getJson';

export const REQUEST_UIELEMENTS = 'REQUEST_UIELEMENTS';
export function requestUIElements() {
  return {
    type: REQUEST_UIELEMENTS
  };
}

export const RECEIVE_UIELEMENTS = 'RECEIVE_UIELEMENTS';
export function receiveUIElements(params) {
  return {
    type: RECEIVE_UIELEMENTS,
    uiElements: params,
    receivedAt: Date.now()
  };
}

export function fetchUIElements() {
  return (dispatch) => {
    dispatch(requestUIElements());
    return getJson('ui_elements', (json) => {
      dispatch(receiveUIElements(json));
    });
  };
}
