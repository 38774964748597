import React, { Component } from 'react';
import {View, Text, TextInput, StyleSheet, Image} from 'react-native';
import PropTypes from 'prop-types';
import UIElementText from '../uiElementText';
import ClickTap from '../clickTap';
import selectionButtonImage from '../../images/selectionButton.png';
import optionSelected from '../../images/optionSelected.png';
import optionUnselected from '../../images/optionUnselected.png';

class CenterOfGravityButton extends Component {

  shouldComponentUpdate(nextProps) {
    return (nextProps.ConfiguratorState.torque.unitOfMeasure !== this.props.ConfiguratorState.torque.unitOfMeasure ||
      nextProps.ConfiguratorState.torque.productWeightDisplay !== this.props.ConfiguratorState.torque.productWeightDisplay ||
      nextProps.ConfiguratorState.torque.lengthDisplay !== this.props.ConfiguratorState.torque.lengthDisplay ||
      nextProps.ConfiguratorState.torque.distanceToCenterOfGravityDisplay !== this.props.ConfiguratorState.torque.distanceToCenterOfGravityDisplay ||
      nextProps.ConfiguratorState.torque.centerOfGravityEvenlyDistributed !== this.props.ConfiguratorState.torque.centerOfGravityEvenlyDistributed);
  }

  evenDistributionChanged(e) {
    this.props.torqueChanged({centerOfGravityEvenlyDistributed: !this.props.ConfiguratorState.torque.centerOfGravityEvenlyDistributed });
  }

  distanceToCOGChanged(amount) {
    this.props.torqueChanged({distanceToCenterOfGravity: amount});
  }

  render() {
    const torqueStyles = this.props.styleSheet
    const unitOfMeasure = this.props.ConfiguratorState.torque.unitOfMeasure === 'metric' ? 'cm' : 'in';
    
    return (
      <View style={torqueStyles.torqueControlsButton}>
        <Image resizeMode="stretch" style={torqueStyles.backgroundImage} source={selectionButtonImage}/>
        <View style={torqueStyles.torqueControlsButtonPadding}>
          <UIElementText
            style={StyleSheet.flatten(torqueStyles.torqueControlsButtonText)}
            languageItemKey="center_of_gravity"
            defaultText="CENTER OF GRAVITY" />
          <View style={{flexDirection: "row", flexWrap: "wrap", alignItems: "center", marginBottom: 5}}>
            <TextInput type="text"
              maxLength={8}
              style={[torqueStyles.torqueControlsButtonValue, this.props.ConfiguratorState.torque.centerOfGravityEvenlyDistributed ? torqueStyles.torqueControlsButtonValueDisabled : null ]}
              value={this.props.ConfiguratorState.torque.distanceToCenterOfGravityDisplay}
              disabled={this.props.ConfiguratorState.torque.centerOfGravityEvenlyDistributed}
              onChangeText={this.distanceToCOGChanged.bind(this)} />
            <Text style={torqueStyles.torqueControlsButtonTextSmall}>{unitOfMeasure}</Text>
          </View>
          <ClickTap onClick={this.evenDistributionChanged.bind(this)}>
            <View style={{flexDirection: "row", flexWrap: "wrap"}}>
              <View style={[torqueStyles.unitOfMeasureSelectBox, {width: "100%"}]}>
                <Image style={torqueStyles.unitOfMeasureCheckBox}
                       source={this.props.ConfiguratorState.torque.centerOfGravityEvenlyDistributed ? optionSelected : optionUnselected}/>
                <UIElementText
                  style={StyleSheet.flatten(torqueStyles.torqueControlsButtonTextSmall)}
                  languageItemKey="even_distribution"
                  defaultText="Product Weight is Evenly Distributed" />
              </View>
            </View>
          </ClickTap>
        </View>
      </View>
    );
  }
}

CenterOfGravityButton.propTypes = {
  ConfiguratorState: PropTypes.object,
  torqueChanged: PropTypes.func
};

export default CenterOfGravityButton;
