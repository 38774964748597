import React, { Component } from 'react';
import {View, Dimensions, StyleSheet, StatusBar, Platform} from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Layouts } from "../layout";
import * as selectionParameterActions from '../actions/selectionParameterActions';
import * as configuratorStateActions from '../actions/configuratorStateActions';
import * as languageActions from '../actions/languageActions';
import * as productActions from '../actions/productActions';

import Header from '../components/header';
import Parameters from '../components/parameters';
import Torque from '../components/torque';
import Results from '../components/results';
import ProductInfo from '../components/productInfo';
import ContactMeNow from '../components/contactMeNow';
import Loading from '../components/loading';

import { getResponsiveStylesheet } from "../layout";
import defaultStyles from './AppStyles';
import tabletStyles from './tabletStyles';
import phabletStyles from "./phabletStyles";
import UIElementText from "../components/uiElementText";

class App extends Component {

  constructor(props) {
    super(props);
    this.handleDimensionChange = this.handleDimensionChange.bind(this);
    if (Platform.OS === "android") {
      StatusBar.setHidden(true);
    }
  }

  componentDidMount() {
    this.dimensionsSubscription = Dimensions.addEventListener("change", this.handleDimensionChange)
  }
  
  componentWillUnmount() {
    this.dimensionsSubscription?.remove();
  }
  
  handleDimensionChange({window, screen}) {
    this.props.calculateStyle();
  }

  render() {
    const styleState = this.props.ConfiguratorState.style;
    const styles = getResponsiveStylesheet(styleState, {
      default: defaultStyles,
      desktop: defaultStyles,
      tablet: tabletStyles,
      phablet: phabletStyles,
      phone: phabletStyles,
    });
    
    const heightStyle = {
      height: this.props.ConfiguratorState.style.appHeight,
      maxHeight: this.props.ConfiguratorState.style.appHeight,
    };

    let bodyStyle = {
      fontSize: '11pt'
    };
    
    if (this.props.ConfiguratorState.style.platform === '')
    switch (this.props.ConfiguratorState.style.layout) {
      case Layouts.PHABLET:
      case Layouts.PHONE:
        if (this.props.ConfiguratorState.style.platform === 'ios')
          bodyStyle.fontSize = '8pt';
        else if (this.props.ConfiguratorState.style.platform === 'android')
          bodyStyle.fontSize = '10pt';
        break;
      default: 
        bodyStyle.fontSize = '11pt';
    }

    return (
      <View style={styles.body}>
        <View style={[styles.wrapper, bodyStyle]}>
          
          {this.props.ConfiguratorState.contactMeNowEnabled &&
            <ContactMeNow
              ConfiguratorState={this.props.ConfiguratorState}
              contactMeNowClicked={this.props.contactMeNowClicked}
              contactMeNowFormChanged={this.props.contactMeNowFormChanged}
              postContactForm={this.props.postContactForm} />
          }
          
          <Header
            ConfiguratorState={this.props.ConfiguratorState}
            Languages={this.props.Languages.data}
            currentLanguageID={this.props.ConfiguratorState.currentLanguageID}
            selectLanguage={this.props.selectLanguage}
            calculateTorqueClicked={this.props.calculateTorqueClicked}/>

          <View style={[styles.app, heightStyle]}>
            <Parameters
              SelectionParameters={this.props.SelectionParameters.data}
              selectionParameterClicked={this.props.selectionParameterClicked}
              selectionParameterOptionClicked={this.props.selectionParameterOptionClicked}
              ConfiguratorState={this.props.ConfiguratorState} />
            <Torque
              ConfiguratorState={this.props.ConfiguratorState}
              torqueChanged={this.props.torqueChanged}
              resetAll={this.props.resetAll}/>
            <ProductInfo
              isOpen={this.props.ConfiguratorState.productEnabled !== null}
              close={this.props.productClicked}
              ConfiguratorState={this.props.ConfiguratorState}
              product={this.props.Products.data.find((p)=> {
                return p.id === this.props.ConfiguratorState.productEnabled;
              })}/>
            <Results
              productClicked={this.props.productClicked}
              contactMeNowClicked={this.props.contactMeNowClicked}
              ConfiguratorState={this.props.ConfiguratorState }/>
          </View>
          
          <View style={[styles.footer, {height: this.props.ConfiguratorState.style.footerHeight, }]}>
            <UIElementText
                style={StyleSheet.flatten(styles.footerText)}
                languageItemKey="disclaimer"
                defaultText="This tool is intended as a general guide only.  Product performance will vary based application dynamics and environment. Please contact Reell to verify product fit and function for your application." />
          </View>
          
          <Loading active={this.props.ConfiguratorState.loading} />
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return Object.assign({},
    bindActionCreators(configuratorStateActions, dispatch),
    bindActionCreators(selectionParameterActions, dispatch),
    bindActionCreators(languageActions, dispatch),
    bindActionCreators(productActions, dispatch)
  );
}

App.propTypes = {
  SelectionParameters: PropTypes.object,
  Products: PropTypes.object,
  Languages: PropTypes.object,
  ConfiguratorState: PropTypes.object,
  selectLanguage: PropTypes.func,
  selectionParameterClicked: PropTypes.func,
  selectionParameterOptionClicked: PropTypes.func,
  productClicked: PropTypes.func,
  contactMeNowClicked: PropTypes.func,
  contactMeNowFormChanged: PropTypes.func,
  postContactForm: PropTypes.func,
  torqueChanged: PropTypes.func,
  resetAll: PropTypes.func,
  closeModals: PropTypes.func,
  calculateTorqueClicked: PropTypes.func,
  calculateStyle: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
