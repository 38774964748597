import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  productInfoWrapper: {
    position: "absolute",
    right: "1%",
    width: 460,
    height: "98%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
  },

  visible: {
    opacity: 1,
    zIndex: 120,
  },
});

export default styles;
