import React, { Component } from 'react';
import { View, Image, Text, StyleSheet } from "react-native";
import PropTypes from 'prop-types';
import LanguageControl from './languageControl';
import UIElementText from './uiElementText';

import ClickTap from './clickTap';
import { Layouts, getResponsiveStylesheet, getMobileOperatingSystem } from "../layout";
import defaultStyles from './headerStyles/shared';
import tabletStyles from './headerStyles/tablet';
import phabletStyles from './headerStyles/phablet';
import logo from '../images/reellLogo.png';

import tabImageLeft from '../images/tabLeft.png';
import tabImageRight from '../images/tabRight.png';


class Header extends Component {

  shouldComponentUpdate(nextProps) {
    return (nextProps.ConfiguratorState.calculateTorqueEnabled !== this.props.ConfiguratorState.calculateTorqueEnabled) ||
      nextProps.ConfiguratorState.productEnabled !== this.props.ConfiguratorState.productEnabled ||
      nextProps.ConfiguratorState.selectionParameterEnabled !== this.props.ConfiguratorState.selectionParameterEnabled ||
      nextProps.currentLanguageID !== this.props.currentLanguageID ||
      nextProps.ConfiguratorState.style.width !== this.props.ConfiguratorState.style.width ||
      nextProps.ConfiguratorState.style.height !== this.props.ConfiguratorState.style.height ||
      nextProps.ConfiguratorState.style.layout !== this.props.ConfiguratorState.style.layout;
  }

  calculateTorqueClicked() {
    if (!this.props.ConfiguratorState.calculateTorqueEnabled) {
      this.props.calculateTorqueClicked();
    }
  }

  parametersClicked() {
    if (this.props.ConfiguratorState.calculateTorqueEnabled) {
      this.props.calculateTorqueClicked();
    }
  }

  render() {
    const showMobileBanner = (this.props.ConfiguratorState.style.platform === 'web' && getMobileOperatingSystem() !== "unknown");
    const disableClickableTabs = ((this.props.ConfiguratorState.style.layout !== Layouts.PHABLET && 
      this.props.ConfiguratorState.style.layout !== Layouts.PHONE) ||
      this.props.ConfiguratorState.productEnabled !== null || this.props.ConfiguratorState.selectionParameterEnabled !== null );
    const styleState = this.props.ConfiguratorState.style;
    const headerStyles = getResponsiveStylesheet(styleState, {
      default: defaultStyles,
      desktop: defaultStyles,
      tablet: tabletStyles,
      phablet: phabletStyles,
      phone: phabletStyles,
    });
    
    return (
      <View id="header" style={headerStyles.header}>
        <View style={headerStyles.topLine}>
          <Image style={headerStyles.motionImage} source={require('../images/motion.png')} />
          <LanguageControl
            Languages={this.props.Languages}
            currentLanguageID={this.props.currentLanguageID}
            selectLanguage={this.props.selectLanguage} />
        </View>
        {showMobileBanner &&
          <View style={headerStyles.appBanner}>
            <a href="https://reell.com/dlguide">
              <Text style={headerStyles.appBannerText}>Download the APP!</Text>
            </a>
          </View>
        }
        <View style={headerStyles.mainTitleWrapper}>
          <Image resizeMode="contain" style={headerStyles.logo} source={logo} />
          <View style={headerStyles.mainTitle}>
            <UIElementText
              style={StyleSheet.flatten(headerStyles.mainTitleText)}
              languageItemKey="hinge_selection_guide"
              defaultText="HINGE SELECTION GUIDE" />
          </View>

          <View style={headerStyles.titles}>
            <View style={headerStyles.tabImage} />
            <Image 
              resizeMode="stretch"
              source={this.props.ConfiguratorState.calculateTorqueEnabled ? tabImageRight : tabImageLeft}
              style={headerStyles.tabImage} />
            <ClickTap style={headerStyles.parameters}
                      disabled={disableClickableTabs}
                      onClick={this.parametersClicked.bind(this)} >
              <UIElementText
                style={StyleSheet.flatten(headerStyles.titlesText)}
                languageItemKey="parameters"
                defaultText="PARAMETERS" />
            </ClickTap>
            <ClickTap style={headerStyles.torque}
                      disabled={disableClickableTabs}
                      onClick={this.calculateTorqueClicked.bind(this)} >
              <UIElementText
                style={StyleSheet.flatten(headerStyles.titlesText)}
                languageItemKey="calculate_torque"
                defaultText="CALCULATE TORQUE" />
            </ClickTap>
            <View style={headerStyles.results}>
              <UIElementText
                style={StyleSheet.flatten(headerStyles.titlesText)}
                languageItemKey="recommended_products"
                defaultText="RECOMMENDED<br/>PRODUCTS" />
            </View>
          </View>
          
        </View>
      </View>
    );
  }
}

Header.propTypes = {
  ConfiguratorState: PropTypes.object,
  Languages: PropTypes.array,
  currentLanguageID: PropTypes.number,
  selectLanguage: PropTypes.func,
  onRender: PropTypes.func,
  calculateTorqueClicked: PropTypes.func
};

export default Header;