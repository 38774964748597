import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  productInfoWrapper: {
    position: "absolute",
    right: "26%",
    top: 10,
    width: 460,
    height: "98%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
  },
  
  backgroundImage: {
    height: 460,
    width: "100%",
    position: "absolute",
    zIndex: -1,
  },

  visible: {
    opacity: 1,
    zIndex: 10,
    // transition: "0.2s",

  },

  productInfo: {
    position: "relative",
    width: "100%",
    height: 460,
    paddingTop: 55,
    paddingBottom: 30,
    paddingLeft: 70,
    paddingRight: 20,
    zIndex: -1,
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: "bold",
    fontSize: 15,
  },

  tagline: {
    fontSize: 12,
    marginBottom: 14,
  },

  details: {
    fontSize: 12,
  },
  
  productLinkWrapper: {
    position: "absolute",
    left: -22,
    top: 254,
    height: 63,
    width: 167,
  },
  
  productLink: {
    position: "absolute",
    left: -22,
    top: 254,
    height: 63,
    width: 167,
    fontSize: 13,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  closeButtonText: {
    fontSize: 10,
    color: "black",
  },
  closeButtonImage: {
    height: 12,
    width: 12,
    marginRight: 5,
  },

});

export default styles;
