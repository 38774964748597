import React, { Component } from 'react';
import { View, Image } from 'react-native';
import PropTypes from 'prop-types';
import RotationAngle from './rotationAngle';
import { TORQUE_WINDOW_STATES } from '../../../actions/configuratorStateActions';

import torqueInfoBackground from '../../../images/torqueInfoBackground.png';

import { getResponsiveStylesheet } from "../../../layout";
import defaultStyles from './torqueControlsInfoStyles';
import tabletStyles from "./tabletStyles";
import phabletStyles from "./phabletStyles";

class TorqueControlsInfo extends Component {

  shouldComponentUpdate(nextProps) {
    return (nextProps.ConfiguratorState.torque.unitOfMeasure !== this.props.ConfiguratorState.torque.unitOfMeasure ||
      nextProps.ConfiguratorState.torque.productWeightDisplay !== this.props.ConfiguratorState.torque.productWeightDisplay ||
      nextProps.ConfiguratorState.torque.lengthDisplay !== this.props.ConfiguratorState.torque.lengthDisplay ||
      nextProps.ConfiguratorState.torque.distanceToCenterOfGravityDisplay !== this.props.ConfiguratorState.torque.distanceToCenterOfGravityDisplay ||
      nextProps.ConfiguratorState.torque.centerOfGravityEvenlyDistributed !== this.props.ConfiguratorState.torque.centerOfGravityEvenlyDistributed ||
      nextProps.ConfiguratorState.torque.rotationAngleDisplay !== this.props.ConfiguratorState.torque.rotationAngleDisplay) ||
      nextProps.ConfiguratorState.style.width !== this.props.ConfiguratorState.style.width ||
      nextProps.ConfiguratorState.style.height !== this.props.ConfiguratorState.style.height ||
      nextProps.ConfiguratorState.style.layout !== this.props.ConfiguratorState.style.layout;
  }

  render() {
    const styleState = this.props.ConfiguratorState.style;
    const torqueControlsInfoStyles = getResponsiveStylesheet(styleState, {
      default: defaultStyles,
      desktop: defaultStyles,
      tablet: tabletStyles,
      phablet: phabletStyles,
      phone: phabletStyles,
    });
    
    const currentWindow = this.props.ConfiguratorState.torque.currentWindow;
    return (
      <View style={{flex: 1}}>
        <Image resizeMode="stretch" style={torqueControlsInfoStyles.backgroundImage} source={torqueInfoBackground}/>
        <View style={torqueControlsInfoStyles.torqueControlsInfo}>
          <RotationAngle
            isActive={currentWindow === TORQUE_WINDOW_STATES.ROTATION_ANGLE}
            stylesheet={torqueControlsInfoStyles}
            {...this.props} />
        </View>
      </View>
    );
  }
}

TorqueControlsInfo.propTypes = {
  ConfiguratorState: PropTypes.object
};

export default TorqueControlsInfo;
