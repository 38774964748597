import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  productInfoWrapper: {
    position: "absolute",
    right: "3%",
    top: 20,
    width: 460,
    height: "98%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    opacity: 0,
  },

});

export default styles;
