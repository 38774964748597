import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  app: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    position: "relative",
    backgroundColor: "#212e36",
    minHeight: 486,
    height: "100%",
  }
});

export default styles;