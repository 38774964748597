import {Platform, StyleSheet} from 'react-native';

const styles = StyleSheet.create({

  torque: {
    borderTopWidth: 4,
    borderTopColor: "red",
    marginLeft: 0,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    zIndex: 120,
    width: "100%",
    height: "100%",
    left: 0,
  },

});

export default styles;