import React, { Component } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import SelectionParameterOptions from './selectionParameterOptions';
import SelectionParameterOptionsDisplay from './selectionParameterOptionsDisplay';
import LanguageItemText from '../languageItemText';
import ClickTap from '../clickTap';

import selectionButtonImage from '../../images/selectionButton.png';
import selectionParameterArrow from '../../images/selectionParameterArrow.png';

class SelectionParameter extends Component {

  clicked(e) {
    this.props.selectionParameterClicked(this.props.selectionParameter.id);
  }

  selectionParameterOptionClicked(selectionParameterOptionID) {
    this.props.selectionParameterOptionClicked(this.props.selectionParameter.id, selectionParameterOptionID);
  }

  render() {
    const selectionParametersStyles = this.props.Stylesheet;
    
    let selectionParameterOptionsSelected = this.props.ConfiguratorState.selectionParameterOptionsEnabled.find((spo) => {
      return spo.id === this.props.selectionParameter.id;
    });
    selectionParameterOptionsSelected = selectionParameterOptionsSelected ? selectionParameterOptionsSelected.options : null;

    return (
      <>
        <View style={{flex: 1}}>
          <ClickTap id={`param${this.props.selectionParameter.id}`}
            style={[selectionParametersStyles.selectionParameter, 
              this.props.optionsActive ? selectionParametersStyles.selectionParameterActive : null]}
            onClick={this.clicked.bind(this)}>
            <Image style={selectionParametersStyles.backgroundImage}
                   resizeMode="stretch"
                   source={selectionButtonImage}/>
            <View style={selectionParametersStyles.selectionParameterPadding}>
              <LanguageItemText
                style={StyleSheet.flatten(selectionParametersStyles.selectionParameterText)}
                languageItemKey="label"
                defaultText={this.props.selectionParameter.name}
                languageItems={this.props.selectionParameter.language_items} />
              <SelectionParameterOptionsDisplay
                selectionParameterOptions={this.props.selectionParameter.selection_parameter_options}
                selectionParameterOptionsSelected={selectionParameterOptionsSelected} />
            </View>
            {this.props.optionsActive ? (
              <>
                <View style={selectionParametersStyles.selectionParameterActiveBefore}/>
                <View style={selectionParametersStyles.selectionParameterActiveAfter}>
                  <Image resizeMode="contain" style={selectionParametersStyles.backgroundImage} source={selectionParameterArrow}/>
                </View>
              </>
            ) : null}
          </ClickTap>
        </View>
        <SelectionParameterOptions
          optionsActive={this.props.optionsActive}
          selectionParameterID={this.props.selectionParameter.id}
          selectionParameterOptions={this.props.selectionParameter.selection_parameter_options}
          selectionParameterOptionClicked={this.selectionParameterOptionClicked.bind(this)}
          close={this.clicked.bind(this)}
          ConfiguratorState={this.props.ConfiguratorState}
          Stylesheet={this.props.Stylesheet}/>
      </>
    );
  }
}

SelectionParameter.propTypes = {
  selectionParameter: PropTypes.object,
  optionsActive: PropTypes.bool,
  ConfiguratorState: PropTypes.object,
  StyleSheet: PropTypes.object,
  selectionParameterClicked: PropTypes.func,
  selectionParameterOptionClicked: PropTypes.func
};

export default SelectionParameter;
