import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  
  header: {
    backgroundColor: "white",
    position: "relative",
  },
  
  topLine: {
    position: "absolute",
    top:0,
    right: 0,
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    zIndex: 10,
  },

  motionImage: {
    width: 70,
    height: 30
  },
  appBanner: {
    position: "absolute",
    top: 30,
    right: 0,
    backgroundColor: "rgba(21, 88, 189, 0.95)",
    height: 30,
    zIndex: 100,
    color: "white",
  },
  appBannerText: {
    lineHeight: 30,
    fontSize: 14,
    fontWeight: "bold",
    paddingRight: 10,
    paddingLeft: 10,
    color: "white",
  },
  mainTitleWrapper: {
    height: 196,
  },
  mainTitle: {
    width: "100%",
    alignItems: "center",
    paddingTop: 43,
    margin: 0,
    flex: 1,
  },
  
  mainTitleText: {
    height: 100,
    lineHeight: 90,
    color: "black",
    fontSize: 26,
    fontWeight: "bold",
  },

  logo: {
    position: "absolute",
    marginTop: 15,
    marginLeft: 35,
    height: 107,
    width: 185,
  },
  
  titles: {
    backgroundColor: "#212e36",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    textAlign: "center",
    height: 54,
    position: "relative",
  },

  titlesText: {
    flex: 1,
    color: "#F1F1F1",
    fontWeight: "bold",
    textAlign: "center",
  },
  
  parameters: {
    flex: 1,
    zIndex: 2,
  },
  torque: {
    flex: 2,
    zIndex: 2,
  },
  results: {
    flex: 1,
  },
  
  tabImage: {
    display: "none",
    position: "absolute",
    top: -2,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  },

});

export default styles;
