import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  
  torqueControlsInfo: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
    alignItems: "center",
    zIndex: 10,
  },
  
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: -1,
  },
  
  text: {
    fontSize: 10,
    color: "white",
  },
  textBulletBlock: {
    flexDirection: "row", 
    justifyContent: "flex-start",
    marginBottom: 3,
  },
  hidden: {
    display: "none",
  },
  visible: {
    display: "flex",
  },

  instructions: {
      paddingBottom: 10,
      paddingLeft: 15,
  },
  
  rotationAngle: {
    justifyContent: "flex-start",
    height: "100%",
  },
  
  widgetWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 50,
    touchAction: "none",
  },
  widgetWrapperLabel: {
    paddingRight: "5%",
    flexBasis: "30%",
    flexGrow: 0,
    flex: 1,
  },

  rotationAngleControl: {
    position: "relative",
    // display: "inline-block",
    width: 140,
    height: 116,
    marginTop: 22,
    marginBottom: 22,
    marginLeft: 20,
    marginRight: 20,
  },
  rotationAngleControlBase: {
    width: 140,
    height: 116,
  },
  rotationAngleControlHandle: {
    position: "absolute",
    top: 14,
    left: -23,
    width: 162,
    height: 88,
  },

  weightDistributionControlDiv: {
    marginBottom: 5,
  },
  weightDistributionControl: {
    position: "relative",
    // display: "inline-block",
    width: 180,
    height: 60,
    paddingTop: 5,
    paddingBottom: 10,
    paddingLeft: 0,
    paddingRight: 0,
  },
  weightDistributionControlBase: {
    width: "100%",
    height: "100%",
  },
  weightDistributionControlHandle: {
    position: "absolute",
    top: 6,
    width: 23,
    height: 45,
  },
  WeightDistributionControlArrowBar: {
    position: "absolute",
    top: 9,
    left: 12,
    height: 6,
    backgroundColor: "white",
  },

});

export default styles;