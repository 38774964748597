import { combineReducers } from 'redux';
import SelectionParameters from './selectionParameters';
import Products from './products';
import ConfiguratorState from './configuratorState';
import Languages from './languages';
import UIElements from './uiElements';

// const rootReducer = combineReducers({
//   SelectionParameters,
//   Products,
//   Languages,
//   UIElements,
//   ConfiguratorState
// });

const rootReducer = {
  SelectionParameters,
  Products,
  Languages,
  UIElements,
  ConfiguratorState
};

export default rootReducer;
