import { StyleSheet, Platform } from 'react-native';

const styles = StyleSheet.create({
  results: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    borderTopWidth: 4,
    borderTopColor: "red",
    marginLeft: 10,
    zIndex: 100,
    height: "100%",
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },

  scrollContainer: {
    flex: 1,
    position: "relative",
  },

  scrollable: {
    position: "absolute",
    top: "3.6%",
    right: "7%",
    width: "100%",
    height: "93%",
    paddingTop: "2.2%",
    paddingBottom: "2.2%",
    paddingLeft: "2.1%",
    paddingRight: "2.1%",
  },
  scrollableContentWrapper: {
  },
  
  resultItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "black",
    marginLeft: "17%",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 0,
    borderBottomWidth: 1,
    borderBottomColor: "#b1b1b1",
    position: "relative",
  },
  
  resultItemImage: {
    height: 45,
    width: 65,
    paddingTop: 5,
    paddingBottom: "10%",
    paddingLeft: 5,
    paddingRight: 10,
  },
  
  resultItemTextWrapper: {
    marginLeft: 30,
  },
  resultItemTitle: {
    fontSize: 12,
    fontWeight: "bold",
  },
  resultItemTagline: {
    fontSize: 10,
    fontWeight: "normal",
  },
  activeImage: {
    position: "absolute",
    left: -50,
    width: "122%",
    height: "100%",
  },
  
  contactButton: {
    height: "13.5%",
    minHeight: 50,
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    margin: 5,
    position: "relative",
  },
  contactButtonText: {
    color: "white",
    fontWeight: "bold",
    textTransform: "uppercase",
    zIndex: 15,
  },
  contactButtonPadding: {
    padding: 15,
  }

});

export default styles;