import update from 'immutability-helper';
import {
  SELECT_LANGUAGE,
  CLOSE_MODALS,
  LOAD_COMPLETE,
  SELECTION_PARAMETER_CLICKED,
  SELECTION_PARAMETER_OPTION_CLICKED,
  PRODUCT_CLICKED,
  CONTACT_ME_NOW_CLICKED,
  CONTACT_ME_NOW_FORM_CHANGED,
  CONTACT_ME_NOW_FORM_SUBMITTED,
  CONTACT_ME_NOW_FORM_SUCCESS,
  CONTACT_ME_NOW_FORM_FAILURE,
  TORQUE_WINDOW_STATES,
  TORQUE_BUTTON_CLICKED,
  TORQUE_CHANGED,
  RESET_TORQUE_CALCULATOR,
  RESET_ALL,
  CALCULATE_TORQUE_CLICKED,
  CALCULATE_STYLE,
} from '../actions/configuratorStateActions';

let userData = '';
// const value = '; ' + document.cookie;
// const parts = value.split('; ' + 'rpm_user_info' + '=');
// if (parts.length === 2) {
//   userData =  parts.pop().split(';').shift();
//   userData = decodeURIComponent(userData).split('\n');
// }

const initialState = {
  loading: true,
  currentLanguageID: null,
  commaAsSeparator: false,
  selectionParameterEnabled: null,
  productEnabled: null,
  contactMeNowEnabled: false,
  contactMeNowFormState: null,
  selectionParameterOptionsChanged: false,
  selectionParameterOptionsEnabled: [],
  filteredProducts: [],
  calculateTorqueEnabled: false,
  torque: {
    currentWindow: TORQUE_WINDOW_STATES.ROTATION_ANGLE,
    unitOfMeasure: 'metric',
    productWeight: '',
    productWeightDisplay: '',
    length: '',
    lengthDisplay: '',
    centerOfGravityEvenlyDistributed: true,
    distanceToCenterOfGravity: '',
    distanceToCenterOfGravityDisplay: '',
    rotationAngle: 45,
    rotationAngleDisplay: '45',
    numberOfHinges: 2,
    torquelbin: null,
    torquenm: null,
    torquePerHingelbin: null,
    torquePerHingenm: null,
    torqueFilter: null
  },
  loggedInUser: {
    firstName: userData[0] || '',
    lastName: userData[1] || '',
    company: userData[2] || '',
    phone: userData[3] || '',
    email: userData[4] || '',
    region: 'Americas',
    comments: ''
  },
  style: {
    width: 0,
    height: 0,
    layout: '',
    platform: '',
    headerHeight: 0,
    footerHeight: 0
  }
};

export default function configuratorStateReducer(state = initialState, action: {}) {
  switch (action.type) {
    case SELECT_LANGUAGE: {
      const torque = Object.assign({}, state.torque);
      calculateTorque(torque, action.commaAsSeparator);
      return Object.assign({}, state, {
        currentLanguageID: action.languageID,
        commaAsSeparator: action.commaAsSeparator,
        torque: torque
      });
    }
    case CLOSE_MODALS: {
      return Object.assign({}, state, {
        selectionParameterEnabled: null,
        productEnabled: null,
        contactMeNowEnabled: false,
        calculateTorqueEnabled: false
      });
    }
    case LOAD_COMPLETE: {
      return Object.assign({}, state, {
        loading: false
      });
    }
    case SELECTION_PARAMETER_CLICKED: {
      if (state.selectionParameterEnabled === action.selectionParameterID) {
        return Object.assign({}, state, {
          selectionParameterEnabled: null,
          productEnabled: null
        });
      }
      return Object.assign({}, state, {
        selectionParameterEnabled: action.selectionParameterID,
        productEnabled: null
      });
    }
    case SELECTION_PARAMETER_OPTION_CLICKED: {
      return setSelectionParameterOption(state, action);
    }
    case PRODUCT_CLICKED: {
      if (state.productEnabled === action.productID) {
        return Object.assign({}, state, {
          productEnabled: null,
          selectionParameterEnabled: null
        });
      }
      return Object.assign({}, state, {
        productEnabled: action.productID,
        selectionParameterEnabled: null
      });
    }
    case CONTACT_ME_NOW_CLICKED: {
      return Object.assign({}, state, {
        contactMeNowEnabled: !state.contactMeNowEnabled,
        contactMeNowFormState: null
      });
    }
    case CONTACT_ME_NOW_FORM_CHANGED: {
      const loggedInUser = Object.assign({}, state.loggedInUser, action.formData);
      return Object.assign({}, state, {
        loggedInUser: loggedInUser
      });
    }
    case CONTACT_ME_NOW_FORM_SUBMITTED: {
      return Object.assign({}, state, {
        contactMeNowFormState: CONTACT_ME_NOW_FORM_SUBMITTED
      });
    }
    case CONTACT_ME_NOW_FORM_SUCCESS: {
      return Object.assign({}, state, {
        contactMeNowFormState: CONTACT_ME_NOW_FORM_SUCCESS
      });
    }
    case CONTACT_ME_NOW_FORM_FAILURE: {
      return Object.assign({}, state, {
        contactMeNowFormState: CONTACT_ME_NOW_FORM_FAILURE
      });
    }
    case CALCULATE_TORQUE_CLICKED: {
      return Object.assign({}, state, {
        calculateTorqueEnabled: !state.calculateTorqueEnabled
      });
    }
    case TORQUE_BUTTON_CLICKED: {
      const torque = Object.assign({}, state.torque, {
        currentWindow: action.torqueWindowState
      });
      return Object.assign({}, state, {
        torque: torque
      });
    }
    case TORQUE_CHANGED: {
      let torque = state.torque;
      if (action.torqueHash.hasOwnProperty('unitOfMeasure') && action.torqueHash.unitOfMeasure !== state.torque.unitOfMeasure) {
        torque = convertTorque(state.torque);
      } else if ( action.torqueHash.centerOfGravityEvenlyDistributed ) {
        torque = Object.assign({}, state.torque, action.torqueHash, {
          distanceToCenterOfGravity: state.torque.length ? parseFloat((state.torque.length / 2).toFixed(2)) : '',
          distanceToCenterOfGravityDisplay: state.torque.length ? parseFloat((state.torque.length / 2).toFixed(2)).toString(10) : ''
        });
      } else if ( action.torqueHash.hasOwnProperty('length') ) {
        const newLength = validateFloat(action.torqueHash.length);
        if (newLength === null) {
          return Object.assign({}, state);
        }
        torque = Object.assign({}, state.torque, {
          length: newLength,
          lengthDisplay: newLength === '' ? newLength : action.torqueHash.length.trim()
        });

        if (state.torque.centerOfGravityEvenlyDistributed) {
          if (newLength === '') {
            torque = Object.assign({}, torque, {
              distanceToCenterOfGravity: '',
              distanceToCenterOfGravityDisplay: ''
            });
          } else {
            const newCoG = parseFloat((newLength / 2).toFixed(2));
            torque = Object.assign({}, torque, {
              distanceToCenterOfGravity: newCoG,
              distanceToCenterOfGravityDisplay: newCoG.toString(10)
            });
          }
        }
      } else if ( action.torqueHash.hasOwnProperty('productWeight') ) {
        const newWeight = validateFloat(action.torqueHash.productWeight);
        if (newWeight === null) {
          return Object.assign({}, state);
        }
        torque = Object.assign({}, state.torque, {
          productWeight: newWeight,
          productWeightDisplay: newWeight === '' ? newWeight : action.torqueHash.productWeight.trim()
        });
      } else if ( action.torqueHash.hasOwnProperty('distanceToCenterOfGravity') ) {
        const newCoG = validateFloat(action.torqueHash.distanceToCenterOfGravity);
        if (newCoG === null) {
          return Object.assign({}, state);
        }
        torque = Object.assign({}, state.torque, {
          distanceToCenterOfGravity: newCoG,
          distanceToCenterOfGravityDisplay: newCoG === '' ? newCoG : action.torqueHash.distanceToCenterOfGravity.trim()
        });
      } else if ( action.torqueHash.hasOwnProperty('rotationAngle') ) {
        const newAngle = validateFloat(action.torqueHash.rotationAngle);
        if (newAngle === null || newAngle < 0 || newAngle > 360) {
          return Object.assign({}, state);
        }

        torque = Object.assign({}, state.torque, {
          rotationAngle: newAngle,
          rotationAngleDisplay: newAngle === '' ? newAngle : action.torqueHash.rotationAngle.trim()
        });
      } else {
        torque = Object.assign({}, state.torque, action.torqueHash);
      }
      calculateTorque(torque, state.commaAsSeparator);
      return Object.assign({}, state, {
        torque: torque,
        filteredProducts: filterProducts(state.selectionParameterOptionsEnabled, torque.torqueFilter, action.products)
      });
    }
    case RESET_TORQUE_CALCULATOR: {
      const torque = Object.assign({}, initialState.torque);
      torque.currentWindow = state.torque.currentWindow;
      return Object.assign({}, state, {
        torque: torque,
        filteredProducts: filterProducts(state.selectionParameterOptionsEnabled, null, action.products)
      });
    }
    case RESET_ALL: {
      const torque = Object.assign({}, initialState.torque);
      torque.currentWindow = state.torque.currentWindow;
      let newState = Object.assign({}, state, {selectionParameterOptionsEnabled: []});

      for (const selectionParameter of action.selectionParameters) {
        for (const selectionParameterOption of selectionParameter.selection_parameter_options) {
          action.selectionParameterID = selectionParameter.id;
          action.selectionParameterOptionID = selectionParameterOption.id;
          newState = setSelectionParameterOption(newState, action);
        }
      }
      return Object.assign({}, newState, {
        torque: torque,
        filteredProducts: filterProducts(newState.selectionParameterOptionsEnabled, null, action.products)
      });
    }
    case CALCULATE_STYLE: {
      return Object.assign({}, state, {
        style: Object.assign({}, state.style, action.style)
      });
    }
    default: {
      return state;
    }
  }
}

function validateFloat(numberToValidate) {
  // trim blanks
  const newReturnValue = numberToValidate.trim();

  if (newReturnValue === '') {
    return '';
  }

  // convert commas to periods for decimal
  const newValue = newReturnValue.replace(/,/g, '.');

  // validate only numbers and .
  if (!/^[0-9\.]+$/.test(newValue)) {
    return null;
  }

  // more than 1 decimal is an invalid number, return invalidValue
  if ((newValue.match(/\./g) || []).length > 1) {
    return null;
  }

  // "." should be valid
  if (newValue === '.') {
    return 0;
  }

  // parse string, if valid return new string else return invalidValue
  const parsedValue = parseFloat(newValue);
  if ( isNaN( parsedValue ) ) {
    return null;
  }

  return parsedValue;
}

function torqueFromHash(torqueHash) {
  if (torqueHash.productWeight === 0 || torqueHash.productLength === 0 || torqueHash.distanceToCenterOfGravity === 0) {
    return null;
  }
  let force = 0;
  let tHash = torqueHash;
  if (torqueHash.unitOfMeasure === 'english') {
    tHash = convertTorqueToMetric(torqueHash);
  }
  force = tHash.distanceToCenterOfGravity * 0.09807;
  return tHash.productWeight * force * Math.abs(Math.cos(tHash.rotationAngle * ((2 * Math.PI) / 360)));
}

function calculateTorque(torqueHash, commaAsSeparator) {
  const t = torqueFromHash(torqueHash);
  if (t) {
    torqueHash.torquenm = parseFloat(t.toFixed(2));
    torqueHash.torquelbin = parseFloat((t * 8.8511).toFixed(2));
    torqueHash.torquePerHingenm = parseFloat((t / (torqueHash.numberOfHinges || 1)).toFixed(2));
    torqueHash.torqueFilter = torqueHash.torquePerHingenm;
    torqueHash.torquePerHingelbin = parseFloat((t * 8.8511 / (torqueHash.numberOfHinges || 1)).toFixed(2));
    if (commaAsSeparator) {
      torqueHash.torquenm = torqueHash.torquenm.toString(10).replace(/\./g, ',');
      torqueHash.torquelbin = torqueHash.torquelbin.toString(10).replace(/\./g, ',');
      torqueHash.torquePerHingenm = torqueHash.torquePerHingenm.toString(10).replace(/\./g, ',');
      torqueHash.torquePerHingelbin = torqueHash.torquePerHingelbin.toString(10).replace(/\./g, ',');
    }
  } else {
    torqueHash.torquenm = null;
    torqueHash.torquelbin = null;
    torqueHash.torquePerHingenm = null;
    torqueHash.torquePerHingelbin = null;
    torqueHash.torqueFilter = null;
  }
}

function convertTorqueToEnglish(torqueHash) {
  let productWeight = initialState.torque.productWeight;
  if (torqueHash.productWeight !== productWeight) {
    productWeight = parseFloat((torqueHash.productWeight * 2.20462).toFixed(2));
  }
  let length = initialState.torque.length;
  if (torqueHash.length !== length) {
    length = parseFloat((torqueHash.length * 0.3937007874).toFixed(2));
  }
  let d2cog = initialState.torque.distanceToCenterOfGravity;
  if (torqueHash.distanceToCenterOfGravity !== d2cog) {
    d2cog = parseFloat((torqueHash.distanceToCenterOfGravity * 0.3937007874).toFixed(2));
  }
  if (torqueHash.centerOfGravityEvenlyDistributed) {
    if (length === initialState.torque.length) {
      d2cog = initialState.torque.distanceToCenterOfGravity;
    } else {
      d2cog = parseFloat((length / 2).toFixed(2));
    }
  }

  return Object.assign({}, torqueHash, {
    unitOfMeasure: 'english',
    productWeight: productWeight,
    productWeightDisplay: productWeight.toString(),
    length: length,
    lengthDisplay: length.toString(),
    distanceToCenterOfGravity: d2cog,
    distanceToCenterOfGravityDisplay: d2cog.toString()
  });
}

function convertTorqueToMetric(torqueHash) {
  let productWeight = initialState.torque.productWeight;
  if (torqueHash.productWeight !== productWeight) {
    productWeight = parseFloat((torqueHash.productWeight / 2.20462).toFixed(2));
  }
  let length = initialState.torque.length;
  if (torqueHash.length !== length) {
    length = parseFloat((torqueHash.length / 0.3937007874).toFixed(2));
  }
  let d2cog = initialState.torque.distanceToCenterOfGravity;
  if (torqueHash.distanceToCenterOfGravity !== d2cog) {
    d2cog = parseFloat((torqueHash.distanceToCenterOfGravity / 0.3937007874).toFixed(2));
  }
  if (torqueHash.centerOfGravityEvenlyDistributed) {
    if (length === initialState.torque.length) {
      d2cog = initialState.torque.distanceToCenterOfGravity;
    } else {
      d2cog = parseFloat((length / 2).toFixed(2));
    }
  }
  return Object.assign({}, torqueHash, {
    unitOfMeasure: 'metric',
    productWeight: productWeight,
    productWeightDisplay: productWeight.toString(),
    length: length,
    lengthDisplay: length.toString(),
    distanceToCenterOfGravity: d2cog,
    distanceToCenterOfGravityDisplay: d2cog.toString()
  });
}

function convertTorque(torqueHash) {
  if (torqueHash.unitOfMeasure === 'metric') {
    return convertTorqueToEnglish(torqueHash);
  }
  return convertTorqueToMetric(torqueHash);
}

function filterProducts(spoe, torque, products) {
  const filteredArray = products.slice();
  for(let i = filteredArray.length - 1; i >= 0; i--) {
    const product = filteredArray[i];
    if (isProductSelected(spoe, product) === false ||
      (torque !== null && (product.torque_min > torque) || product.torque_max < torque) ) {
      filteredArray.splice(i, 1);
    }
  }
  return filteredArray;
}

function isProductSelected(spoe, product) {
  return spoe.every((spog) => { // every group must have a matching selected
    return spog.options.findIndex((spoi) => { // is there a matching selected for this group?
      return product.selection_parameter_options.findIndex((productSpo) => { // does the product have the matching option
        return productSpo.id === spoi;
      }) >= 0;
    }) >= 0;
  });
}

function setSelectionParameterOption(state, action) {
  const selectionParameterIndex = state.selectionParameterOptionsEnabled.findIndex((sp) => {
    return sp.id === action.selectionParameterID;
  });
  if (selectionParameterIndex >= 0) {
    const selectionParameter = state.selectionParameterOptionsEnabled[selectionParameterIndex];
    const index = selectionParameter.options.indexOf(action.selectionParameterOptionID);
    if (index >= 0) {
      return removeSelectionParameterOption(state, action, selectionParameterIndex, index);
    }
    return addSelectionParameterOption(state, action, selectionParameterIndex);
  }
  return addSelectionParameter(state, action);
}

function addSelectionParameter(state, action) {
  const spoe = state.selectionParameterOptionsEnabled.slice();
  spoe.push({
    id: action.selectionParameterID,
    options: [action.selectionParameterOptionID]});
  return Object.assign({}, state, {
    selectionParameterOptionsChanged: !state.selectionParameterOptionsChanged,
    selectionParameterOptionsEnabled: spoe,
    filteredProducts: filterProducts(spoe, state.torque.torqueFilter, action.products)
  });
}


function removeSelectionParameterOption(state, action, selectionParameterIndex, optionIndex) {
  const spoe = update( state.selectionParameterOptionsEnabled, {
    [selectionParameterIndex]: {options: {$splice: [[optionIndex, 1]]}}
  });
  return Object.assign({}, state, {
    selectionParameterOptionsChanged: !state.selectionParameterOptionsChanged,
    selectionParameterOptionsEnabled: spoe,
    filteredProducts: filterProducts(spoe, state.torque.torqueFilter, action.products)
  });
}

function addSelectionParameterOption(state, action, selectionParameterIndex) {
  const spoe = update( state.selectionParameterOptionsEnabled, {
    [selectionParameterIndex]: {options: {$push: [action.selectionParameterOptionID]}}
  });
  return Object.assign({}, state, {
    selectionParameterOptionsChanged: !state.selectionParameterOptionsChanged,
    selectionParameterOptionsEnabled: spoe,
    filteredProducts: filterProducts(spoe, state.torque.torqueFilter, action.products)
  });
}
