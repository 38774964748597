import React, { Component } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import LanguageItem from './languageItem';
import languageControlStyles from './languageControlStyles';


class LanguageControl extends Component {

  render() {
    const languageItems = [];
    for (const language of this.props.Languages) {
      if (language.active) {
        languageItems.push(
          <LanguageItem
            key={language.id}
            language={language}
            active={this.props.currentLanguageID === language.id}
            selectLanguage={this.props.selectLanguage} />
        );
      }
    }
    return (
      <View style={languageControlStyles.languageControl}>
        {languageItems}
      </View>
    );
  }
}

LanguageControl.propTypes = {
  Languages: PropTypes.array.isRequired,
  currentLanguageID: PropTypes.number,
  selectLanguage: PropTypes.func.isRequired
};

export default LanguageControl;
