import React, { Component } from 'react';
import { View, Image } from 'react-native';
import PropTypes from 'prop-types';
import LanguageItemText from '../languageItemText';
import ClickTap from '../clickTap';

import optionsSelected from '../../images/optionSelected.png';
import optionsUnselected from '../../images/optionUnselected.png';

class SelectionParameterOptions extends Component {
  
  clicked(e) {
    this.props.selectionParameterOptionClicked(this.props.selectionParameterOption.id);
  }

  render() {
    const selectionParametersStyles = this.props.Stylesheet;
    
    let icon = '';
    if (this.props.selectionParameterOption.base64_image) {
      let uri = "data:image/png;base64," + this.props.selectionParameterOption.base64_image.replace(/\n/g, '');
      icon = (
        <Image style={selectionParametersStyles.selectionParameterOptionItemIconImage} source={{uri: uri}} />
      );
    }
    return (
      <ClickTap 
        style={[selectionParametersStyles.selectionParameterOptionItem, this.props.selected ? selectionParametersStyles.active : '']} 
        onClick={this.clicked.bind(this)}>
        <Image style={selectionParametersStyles.selectionParameterOptionItemCheckBox}
               source={this.props.selected ? optionsSelected : optionsUnselected}/>
        <View 
          style={selectionParametersStyles.selectionParameterOptionItemIcon}>
          {icon}
        </View>
        <LanguageItemText
          style={selectionParametersStyles.selectionParameterOptionItemText}
          languageItemKey="label"
          defaultText={this.props.selectionParameterOption.name}
          languageItems={this.props.selectionParameterOption.language_items} />
      </ClickTap>
    );
  }
}

SelectionParameterOptions.propTypes = {
  selectionParameterOption: PropTypes.object,
  selected: PropTypes.bool,
  ConfiguratorState: PropTypes.object,
  Stylesheet: PropTypes.object,
  selectionParameterOptionClicked: PropTypes.func
};

export default SelectionParameterOptions;
