import getJson from './getJson';

export const REQUEST_SELECTION_PARAMETERS = 'REQUEST_SELECTION_PARAMETERS';
export function requestSelectionParameters() {
  return {
    type: REQUEST_SELECTION_PARAMETERS
  };
}

export const RECEIVE_SELECTION_PARAMETERS = 'RECEIVE_SELECTION_PARAMETERS';
export function receiveSelectionParameters(params) {
  return {
    type: RECEIVE_SELECTION_PARAMETERS,
    selectionParameters: params,
    receivedAt: Date.now()
  };
}

export function fetchSelectionParameters() {
  return (dispatch) => {
    dispatch(requestSelectionParameters());
    return getJson('selection_parameters', (json) => {
      dispatch(receiveSelectionParameters(json));
    });
  };
}
