import {Platform, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  results: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    borderTopWidth: 4,
    borderTopColor: "red",
    marginLeft: 0,
    zIndex: 100,
    height: "100%",
  },
  
  resultItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "black",
    marginLeft: "17%",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 0,
    borderBottomWidth: 1,
    borderBottomColor: "#b1b1b1",
    position: "relative",
  },

  resultItemImage: {
    height: 45,
    width: 70,
    minWidth: "25%",
    paddingTop: 5,
    paddingBottom: "10%",
    paddingLeft: 5,
    paddingRight: 10,
  },

  activeImage: {
    display: "none",
  },
  
  contactButtonAfter: {
    position: "absolute",
    width: "100%",
    height: "86%",
    right: 5,
    top: "7%",
    zIndex: 10,
  },
  
});

export default styles;