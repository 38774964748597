import React, { Component } from 'react';
import {View, Text, TextInput, StyleSheet, Image} from 'react-native';
import PropTypes from 'prop-types';
import UIElementText from '../uiElementText';

import selectionButtonImage from "../../images/selectionButton.png";

class RotationAngleButton extends Component {

  shouldComponentUpdate(nextProps) {
    return (nextProps.ConfiguratorState.torque.rotationAngleDisplay !== this.props.ConfiguratorState.torque.rotationAngleDisplay);
  }

  rotationAngleChanged(angle) {
    this.props.torqueChanged({rotationAngle: angle });
  }

  render() {
    const torqueStyles = this.props.styleSheet

    return (
      <View style={torqueStyles.torqueControlsButton}>
        <Image resizeMode="stretch" style={torqueStyles.backgroundImage} source={selectionButtonImage}/>
        <View style={torqueStyles.torqueControlsButtonPadding}>
          <UIElementText
            style={StyleSheet.flatten(torqueStyles.torqueControlsButtonText)}
            languageItemKey="rotation_angle"
            defaultText="ROTATION ANGLE" />
          <View style={{flexDirection: "row", flexWrap: "wrap", alignItems: "center"}}>
            <TextInput type="text"
              maxLength={8}
              style={torqueStyles.torqueControlsButtonValue}
              value={this.props.ConfiguratorState.torque.rotationAngleDisplay}
              onChangeText={this.rotationAngleChanged.bind(this)} />
            <Text style={torqueStyles.torqueControlsButtonTextSmall}>deg</Text>
          </View>
        </View>
      </View>
    );
  }
}

RotationAngleButton.propTypes = {
  ConfiguratorState: PropTypes.object,
  torqueChanged: PropTypes.func
};

export default RotationAngleButton;
