import React, { Component } from 'react';
import {View, Text, TextInput, StyleSheet, Image} from 'react-native';
import PropTypes from 'prop-types';
import UIElementText from '../uiElementText';
import selectionButtonImage from '../../images/selectionButton.png';

class ProductWeightButton extends Component {

  shouldComponentUpdate(nextProps) {
    return (nextProps.ConfiguratorState.torque.unitOfMeasure !== this.props.ConfiguratorState.torque.unitOfMeasure ||
            nextProps.ConfiguratorState.torque.productWeightDisplay !== this.props.ConfiguratorState.torque.productWeightDisplay);
  }

  productWeightChanged(weight) {
    this.props.torqueChanged({productWeight: weight});
  }

  render() {
    const torqueStyles = this.props.styleSheet;
    
    const unitOfMeasure = this.props.ConfiguratorState.torque.unitOfMeasure === 'metric' ? 'kg' : 'lb';
    return (
      <View style={torqueStyles.torqueControlsButton}>
        <Image resizeMode="stretch" style={torqueStyles.backgroundImage} source={selectionButtonImage}/>
        <View style={torqueStyles.torqueControlsButtonPadding}>
          <UIElementText
            style={StyleSheet.flatten(torqueStyles.torqueControlsButtonText)}
            languageItemKey="product_weight"
            defaultText="PRODUCT WEIGHT" />
          <View style={{flexDirection: "row", flexWrap: "wrap", alignItems: "center"}}>
            <TextInput type="text"
              maxLength={8}
              style={torqueStyles.torqueControlsButtonValue}
              value={this.props.ConfiguratorState.torque.productWeightDisplay}
              onChangeText={this.productWeightChanged.bind(this)} />
            <Text style={torqueStyles.torqueControlsButtonTextSmall}>{unitOfMeasure}</Text>
          </View>
        </View>
      </View>
    );
  }
}

ProductWeightButton.propTypes = {
  ConfiguratorState: PropTypes.object,
  torqueChanged: PropTypes.func
};

export default ProductWeightButton;
