import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  
  appBanner: {
    position: "absolute",
    backgroundColor: "rgba(21, 88, 189, 0.95)",
    height: 30,
    zIndex: 100,
    color: "white",
    top: 0,
    left: 0,
    right: "initial",
    minWidth: "30%",
    textAlign: "center",
  },
  mainTitleWrapper: {
    height: 156,
  },
  mainTitle: {
    width: "100%",
    alignItems: "flex-end",
    justifyContent: "center",
    paddingTop: 30,
    margin: 0,
    flex: 1,
  },
  mainTitleText: {
    height: 40,
    lineHeight: 40,
    color: "black",
    fontSize: 18,
    fontWeight: "bold",
    marginRight: "3%",
  },
  logo: {
    position: "absolute",
    marginTop: 30,
    marginLeft: 20,
    height: 80,
    width: 80,
  },
  titles: {
    backgroundColor: "#212e36",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    position: "relative",
    height: 38,
  },
  titlesText: {
    color: "#212e36",
    fontWeight: "bold",
  },
  results: {
    flex: 1,
    display: "none",
  },

  tabImage: {
    display: "flex",
    position: "absolute",
    top: -2,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
  
});

export default styles;
