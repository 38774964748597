import React, { Component } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import UIElementText from '../uiElementText';
import ClickTap from '../clickTap';
import selectionButtonImage from '../../images/selectionButton.png';
import optionSelected from '../../images/optionSelected.png';
import optionUnselected from '../../images/optionUnselected.png';

class UnitOfMeasureButton extends Component {

  shouldComponentUpdate(nextProps) {
    return (nextProps.ConfiguratorState.torque.unitOfMeasure !== this.props.ConfiguratorState.torque.unitOfMeasure);
  }

  unitOfMeasureClicked(e, value) {
    this.props.torqueChanged({unitOfMeasure: value});
  }

  render() {
    const torqueStyles = this.props.styleSheet;
    
    return (
      <View style={[torqueStyles.torqueControlsButton, torqueStyles.unitOfMeasure]}>
        <Image resizeMode="stretch" style={torqueStyles.backgroundImage} source={selectionButtonImage}/>
        <View style={torqueStyles.torqueControlsButtonPadding}>
          <UIElementText
            style={StyleSheet.flatten(torqueStyles.torqueControlsButtonText)}
            languageItemKey="unit_of_measure"
            defaultText="UNIT OF MEASURE" />
          <View style={{flexDirection: "row", flexWrap: "wrap"}}>
            <ClickTap onClick={(e) => {this.unitOfMeasureClicked.bind(this)(e, 'metric');}}>
              <View style={torqueStyles.unitOfMeasureSelectBox}>
                <Image style={torqueStyles.unitOfMeasureCheckBox} 
                       source={this.props.ConfiguratorState.torque.unitOfMeasure === 'metric' ? optionSelected : optionUnselected}/>
                <UIElementText
                  style={StyleSheet.flatten(torqueStyles.torqueControlsButtonTextSmall)}
                  languageItemKey="unit_of_measure_metric"
                  defaultText="Metric" />
              </View>
            </ClickTap>
            <ClickTap onClick={(e) => {this.unitOfMeasureClicked.bind(this)(e, 'english');}}>
              <View style={torqueStyles.unitOfMeasureSelectBox}>
                <Image style={torqueStyles.unitOfMeasureCheckBox}
                       source={this.props.ConfiguratorState.torque.unitOfMeasure === 'metric' ? optionUnselected : optionSelected}/>
                <UIElementText
                  style={StyleSheet.flatten(torqueStyles.torqueControlsButtonTextSmall)}
                  languageItemKey="unit_of_measure_english"
                  defaultText="English" />
              </View>
            </ClickTap>
          </View>
        </View>
      </View>
    );
  }
}

UnitOfMeasureButton.propTypes = {
  ConfiguratorState: PropTypes.object,
  torqueChanged: PropTypes.func
};

export default UnitOfMeasureButton;
