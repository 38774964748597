import React, { Component } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import SelectionParameter from './selectionParameter';
import { getResponsiveStylesheet } from "../../layout";
import defaultStyles from './parametersStyles';
import tabletStyles from './tabletStyles';
import phabletStyles from "./phabletStyles";


class Parameters extends Component {

  shouldComponentUpdate(nextProps) {
    return nextProps.ConfiguratorState.currentLanguageID !== this.props.ConfiguratorState.currentLanguageID ||
      nextProps.ConfiguratorState.calculateTorqueEnabled !== this.props.ConfiguratorState.calculateTorqueEnabled ||
      nextProps.ConfiguratorState.selectionParameterEnabled !== this.props.ConfiguratorState.selectionParameterEnabled ||
      nextProps.ConfiguratorState.selectionParameterOptionsChanged !== this.props.ConfiguratorState.selectionParameterOptionsChanged||
      nextProps.ConfiguratorState.style.width !== this.props.ConfiguratorState.style.width ||
      nextProps.ConfiguratorState.style.height !== this.props.ConfiguratorState.style.height ||
      nextProps.ConfiguratorState.style.layout !== this.props.ConfiguratorState.style.layout;
  }

  render() {
    const styleState = this.props.ConfiguratorState.style;
    const selectionParametersStyles = getResponsiveStylesheet(styleState, {
      default: defaultStyles,
      desktop: defaultStyles,
      tablet: tabletStyles,
      phablet: phabletStyles,
      phone: phabletStyles,
    });
    
    const parameterList = [];
    for (const selectionParameter of this.props.SelectionParameters) {
      parameterList.push(
        <SelectionParameter
          key={selectionParameter.id}
          selectionParameter={selectionParameter}
          ConfiguratorState={this.props.ConfiguratorState}
          Stylesheet={selectionParametersStyles}
          selectionParameterClicked={this.props.selectionParameterClicked}
          selectionParameterOptionClicked={this.props.selectionParameterOptionClicked}
          optionsActive={this.props.ConfiguratorState.selectionParameterEnabled === selectionParameter.id}/>
      );
    }

    return (
      <View
        id="parameters"
        className={selectionParametersStyles.parameters}
        style={[selectionParametersStyles.parameters, this.props.ConfiguratorState.selectionParameterEnabled > 0 ? {zIndex: 110} : {zIndex: 1}]}>
        {parameterList}
      </View>
    );
  }
}

Parameters.propTypes = {
  SelectionParameters: PropTypes.array,
  ConfiguratorState: PropTypes.object,
  selectionParameterClicked: PropTypes.func,
  selectionParameterOptionClicked: PropTypes.func
};

export default Parameters;
