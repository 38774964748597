import { StyleSheet } from "react-native";

export const Layouts = {
  DESKTOP: "desktop",
  TABLET: "tablet",
  PHABLET: "phablet",
  PHONE: "phone",
};

export function getLayout(width)
{
  if (width >= 1024)
    return Layouts.DESKTOP;
  if (width >= 768)
    return Layouts.TABLET;
  if (width >= 420)
    return Layouts.PHABLET;
  
  return Layouts.PHONE;
}

export function getResponsiveStylesheet(state, styles)
{
  switch(state.layout) {
    case Layouts.DESKTOP:
      return StyleSheet.flatten([styles.default, styles.desktop]);
    case Layouts.TABLET:
      return StyleSheet.flatten([styles.default, styles.tablet]);
    case Layouts.PHABLET:
      return StyleSheet.flatten([styles.default, styles.phablet]);
    case Layouts.PHONE:
      return StyleSheet.flatten([styles.default, styles.phone]);
  }
  
}

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

// export function calculateLayout() {
//   const windowWidth = Dimensions.get('window').width;
//   const windowHeight = Dimensions.get('window').height;
//  
//   console.log("width:" + windowWidth + " height:" + windowHeight);
// }
//
// function getMobileOperatingSystem() {
//  
//   // if android or iOS do something here and return
//
//   var userAgent = navigator.userAgent || navigator.vendor || window.opera;
//
//   // Windows Phone must come first because its UA also contains "Android"
//   if (/windows phone/i.test(userAgent)) {
//     return "Windows Phone";
//   }
//
//   if (/android/i.test(userAgent)) {
//     return "Android";
//   }
//
//   // iOS detection from: http://stackoverflow.com/a/9039885/177710
//   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
//     return "iOS";
//   }
//
//   return "unknown";
// }