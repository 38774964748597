import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  widgetWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    touchAction: "none",
  },

});

export default styles;