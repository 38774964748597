import React, { Component } from 'react';
import { View, Text } from "react-native";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class UIElementText extends Component {
  render() {
    let outputText = this.props.defaultText;
    const uiElement = this.props.UIElements.find((ui) => {
      return ui.key === this.props.languageItemKey;
    });
    if (uiElement) {
      let languageItem = uiElement.language_items.find((li) => {
        return li.language_id === this.props.currentLanguageID && li.key === 'value';
      });
      if (languageItem) {
        outputText = languageItem.value;
      } else {
        const englishLanguage = this.props.Languages.data.find(language => { return language.name.toUpperCase() === 'ENGLISH'; });
        if (englishLanguage) {
          languageItem = uiElement.language_items.find((li) => {
            return li.language_id === englishLanguage.id && li.key === 'value';
          });
          if (languageItem) {
            outputText = languageItem.value;
          }
        }
      }
    }
    let newStyle = [this.props.style];
    if (outputText === '') {
      newStyle.push = {display: "none"};
    }
    let newValue = '';
    if (this.props.elementType === 'option') {
      newValue = outputText;
    }
    
    outputText = outputText.replace(/<br\/>/g, '\n');
    outputText = outputText.replace(/<BR\/>/, '\n');
    outputText = outputText.replace(/<br>/, '\n');
    outputText = outputText.replace(/<BR>/, '\n');

    return (
      <Text style={newStyle} value={newValue}>
        {outputText}
      </Text>
    );
  }
}

UIElementText.propTypes = {
  style: PropTypes.object,
  languageItemKey: PropTypes.string,
  defaultText: PropTypes.string,
  languageItems: PropTypes.array,
  currentLanguageID: PropTypes.number,
  Languages: PropTypes.object,
  UIElements: PropTypes.array,
  elementType: PropTypes.string
};

function mapStateToProps(state) {
  return {
    Languages: state.Languages,
    UIElements: state.UIElements.data,
    currentLanguageID: state.ConfiguratorState.currentLanguageID
  };
}

export default connect(mapStateToProps)(UIElementText);
