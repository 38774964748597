import {Platform, StyleSheet} from 'react-native';

const styles = StyleSheet.create({

  parameters: {
    flexBasis: "35%",
    flexGrow: 0,
    flexShrink: 1,
    backgroundColor: "white",
    borderTopWidth: 4,
    borderTopColor: "red",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
  },

  selectionParameterText: {
    fontSize: 12,
    color: "#F1F1F1",
    fontWeight: "bold",
    padding: 5,
  },

  selectionParameterActiveAfter: {
    display: "none",
  },

  selectionParameterOptionsDisplay: {
    flexWrap: "wrap",
    flexDirection: "row",
    marginTop: 5,
    zIndex: 100,
    position: "relative",
    maxHeight: 26,
  },

  selectionParameterOptionsDisplayImage: {
    maxHeight: 26,
    height: 30,
    width: 30,
    marginRight: 5,
    zIndex: 100,
  },

  selectionParameterOptionsWrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    minWidth: 320,
    minHeight: "40%",
    height: "100%",
    opacity: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: 5,
  },

  selectionParameterOptionItemIcon: {
    // display: "inline-block",
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 25,
    marginRight: 25,
    height: 40,
    width: 40,
  },

  
});

export default styles;
