import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  
  appBanner: {
    position: "absolute",
    backgroundColor: "rgba(21, 88, 189, 0.95)",
    height: 30,
    zIndex: 100,
    color: "white",
    top: 0,
    left: 0,
    right: "initial",
    minWidth: "30%",
    textAlign: "center",
  },
  mainTitle: {
    width: "100%",
    alignItems: "right",
    paddingTop: 43,
    margin: 0,

    flex: 1,
  },
  mainTitleText: {
    height: 100,
    lineHeight: 90,
    color: "black",
    fontSize: 26,
    fontWeight: "bold",
    textAlign: "right",
    marginRight: "5%",
  },
  logo: {
    position: "absolute",
    marginTop: 50,
    marginLeft: 35,
    height: 75,
    width: 135,
  },
  results: {
    flex: 1,
    display: "none",
  },
});

export default styles;
