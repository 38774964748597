import React, { Component } from 'react';
import {Text, Animated, Platform} from "react-native";
import PropTypes from 'prop-types';
import ClickTap from '../clickTap';

import languageControlStyles from './languageControlStyles';

class LanguageItem extends Component {
  state = { animation: new Animated.Value(0)};
  
  clicked(e) {
    this.props.selectLanguage(this.props.language.id, this.props.language.comma_as_separator);
  }

  render() {
    return (
      <ClickTap 
        style={languageControlStyles.languageItem}
        onMouseEnter={() => {
          Animated.timing(this.state.animation, {
            toValue: 1,
            duration: 200,
            useNativeDriver: Platform.OS !== 'web'
          }).start();
        }}
        onMouseLeave={() => {
          Animated.timing(this.state.animation, {
            toValue: 0,
            duration: 200,
            useNativeDriver: Platform.OS !== 'web'
          }).start();
        }}
        onClick={this.clicked.bind(this)} >
        <Animated.Text style={[languageControlStyles.languageItemText, 
          this.props.active ? languageControlStyles.active : null,
          {color: this.state.animation.interpolate({
              inputRange: [0,1],
              outputRange: ["white","#009cff"]
            })}
        ]}>
          {this.props.language.label}
        </Animated.Text>
      </ClickTap>
    );
  }
}

LanguageItem.propTypes = {
  language: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  selectLanguage: PropTypes.func.isRequired
};

export default LanguageItem;
