import React, { Component } from 'react';
import { View, Image } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import selectionParametersStyles from './parametersStyles';

class SelectionParameterOptionsDisplay extends Component {
  
  getSelectionParameterLabel(selectionParameterOption) {
    let outputText = selectionParameterOption.name;
    let languageItem = selectionParameterOption.language_items.find((li) => {
      return li.language_id === this.props.currentLanguageID && li.key === 'label';
    });
    if (languageItem) {
      outputText = languageItem.value;
    } else {
      const englishLanguage = this.props.Languages.data.find(language => { return language.name.toUpperCase() === 'ENGLISH'; });
      if (englishLanguage) {
        languageItem = selectionParameterOption.language_items.find((li) => {
          return li.language_id === englishLanguage.id && li.key === 'label';
        });
        if (languageItem) {
          outputText = languageItem.value;
        }
      }
    }
    return outputText;
  }
  
  render() {
    const icons = [];
    if (this.props.selectionParameterOptionsSelected) {
      for (const selectionParameterOption of this.props.selectionParameterOptions) {
        const selectedSPO = this.props.selectionParameterOptionsSelected.find((spoID) => {
          return spoID === selectionParameterOption.id;
        });
        if (selectedSPO) {
          let uri = "data:image/png;base64," + selectionParameterOption.base64_image.replace(/\n/g, '');
          icons.push(
            <Image key={selectionParameterOption.id}
                   style={selectionParametersStyles.selectionParameterOptionsDisplayImage}
                   source={{uri: uri}}
                   title={this.getSelectionParameterLabel.bind(this)(selectionParameterOption)}/>
          );
        }
      }
    }
    return (
      <View
        style={selectionParametersStyles.selectionParameterOptionsDisplay}>
        {icons}
      </View>
    );
  }
}

SelectionParameterOptionsDisplay.propTypes = {
  selectionParameterOptions: PropTypes.array,
  selectionParameterOptionsSelected: PropTypes.array,
  currentLanguageID: PropTypes.number,
  Languages: PropTypes.object
};

function mapStateToProps(state) {
  return {
    Languages: state.Languages,
    currentLanguageID: state.ConfiguratorState.currentLanguageID
  };
}

export default connect(mapStateToProps)(SelectionParameterOptionsDisplay);
