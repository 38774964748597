import React, { Component } from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import LanguageItemText from '../languageItemText';
import LanguageItemLink from '../languageItemLink';
import UIElementText from '../uiElementText';
import ClickTap from '../clickTap';

import { getResponsiveStylesheet } from "../../layout";
import defaultStyles from './productInfoStyles';
import tabletStyles from './tablet';
import phabletStyles from "./phablet";

import closeCheckbox from '../../images/closeCheckbox.png';
import infoBackground from '../../images/infoBackground.png';

class ProductInfo extends Component {

  close(e) {
    this.props.close(this.props.product.id);
  }

  render() {
    const styleState = this.props.ConfiguratorState.style;
    const productInfoStyles = getResponsiveStylesheet(styleState, {
      default: defaultStyles,
      desktop: defaultStyles,
      tablet: tabletStyles,
      phablet: phabletStyles,
      phone: phabletStyles,
    });
    
    if (this.props.isOpen === false) {
      return (
        <View style={productInfoStyles.hidden} />
      );
    }

    const productInfoWrapperStyle = {
      width: this.props.ConfiguratorState.style.width < 460 ?
        this.props.ConfiguratorState.style.width : 460
    };

    const productInfoStyle = {
      paddingLeft: this.props.ConfiguratorState.style.width < 460 ? 
        192 - (460 - this.props.ConfiguratorState.style.width) / 2 : 192
    };

    const productLinkStyle = {
      left: this.props.ConfiguratorState.style.width < 460 ? 
        -22 - (460 - this.props.ConfiguratorState.style.width) / 8 : -22
    };

    const productImageStyle = {
      left: this.props.ConfiguratorState.style.width < 460 ? 
        178 - (460 - this.props.ConfiguratorState.style.width) / 2 : 178
    };

    let image = '';
    if (this.props.product.base64_image_small) {
      let uri = "data:image/png;base64," + this.props.product.base64_image_small.replace(/\n/g, '');
      image = (
        <Image resizeMode="center" source={{uri: uri}} style={[{width: 238, height: 159, flex: 1}]}/>
      );
    }
    return (
      <View
        style={[productInfoStyles.productInfoWrapper, productInfoStyles.visible, productInfoWrapperStyle]}>
        <Image resizeMode="stretch" style={productInfoStyles.backgroundImage}
               source={infoBackground}/>
        <View style={[productInfoStyles.productInfo, productInfoStyle]}>
          <View>
            <LanguageItemText
              style={productInfoStyles.title}
              languageItemKey="title"
              defaultText={this.props.product.name}
              languageItems={this.props.product.language_items} />
            <LanguageItemText
              style={productInfoStyles.tagline}
              languageItemKey="tagline"
              defaultText={''}
              languageItems={this.props.product.language_items} />
            <LanguageItemText
              style={productInfoStyles.details}
              languageItemKey="details"
              defaultText=""
              languageItems={this.props.product.language_items} />
          </View>
          {image}
          <LanguageItemLink
            style={StyleSheet.flatten([productInfoStyles.productLink, productLinkStyle])}
            defaultText="https://reell.com"
            target="_blank"
            languageItemKey="url"
            languageItems={this.props.product.language_items} >
            <UIElementText
              style={{color: 'red', fontSize: 9, fontWeight: "bold"}}
              languageItemKey="learn_more"
              defaultText="LEARN MORE:" />
            <UIElementText
              style={{color: 'white', fontSize: 11, fontWeight: "bold"}}
              languageItemKey="direct_product_link"
              defaultText="Direct Product Link" />
            <Text style={{color: '#009cff', fontSize: 10, fontWeight: "bold"}}>www.reell.com</Text>
          </LanguageItemLink>
          <ClickTap
            style={productInfoStyles.closeButton}
            onClick={this.close.bind(this)}>
            <View style={{flexDirection: "row", alignItems: "center"}}>
              <Image source={closeCheckbox} style={productInfoStyles.closeButtonImage}/>
              <UIElementText
                style={StyleSheet.flatten(productInfoStyles.closeButtonText)}
                languageItemKey="close"
                defaultText="CLOSE" />
            </View>
          </ClickTap>
        </View>
      </View>
    );
  }
}

ProductInfo.propTypes = {
  isOpen: PropTypes.bool,
  product: PropTypes.object,
  ConfiguratorState: PropTypes.object,
  close: PropTypes.func
};

export default ProductInfo;
