import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({

  parameters: {
    flex: 1,
    backgroundColor: "white",
    borderTopWidth: 5,
    borderTopColor: "red",
    borderBottomWidth: 4,
    borderBottomColor: "black",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
  },

});

export default styles;
