import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import UIElementText from '../../uiElementText';
import RotationWidget from './rotationWidget';
import CenterOfGravityWidget from './centerOfGravityWidget';

class RotationAngle extends Component {

  render() {
    let torqueControlsInfoStyles = this.props.stylesheet;
    let styles = [torqueControlsInfoStyles.rotationAngle, torqueControlsInfoStyles.visible];
    if (this.props.isActive === false) {
      styles = [torqueControlsInfoStyles.rotationAngle, torqueControlsInfoStyles.hidden];
    }

    return (
      <View style={styles}>
        <View style={torqueControlsInfoStyles.instructions}>
          <View style={torqueControlsInfoStyles.textBulletBlock}>
            <Text style={[torqueControlsInfoStyles.text, {color: "white", paddingRight: 5}]}>{'\u2022'}</Text>
            <UIElementText
              style={StyleSheet.flatten(torqueControlsInfoStyles.text)}
              languageItemKey="torque_calc_instructions_line_1"
              defaultText="" />
          </View>
          <View style={torqueControlsInfoStyles.textBulletBlock}>
            <Text style={[torqueControlsInfoStyles.text, {color: "white", paddingRight: 5}]}>{'\u2022'}</Text>
            <UIElementText
              style={StyleSheet.flatten(torqueControlsInfoStyles.text)}
              languageItemKey="torque_calc_instructions_line_2"
              defaultText="" />
          </View>
          <View style={torqueControlsInfoStyles.textBulletBlock}>
            <Text style={[torqueControlsInfoStyles.text, {color: "white", paddingRight: 5}]}>{'\u2022'}</Text>
            <UIElementText
              style={StyleSheet.flatten(torqueControlsInfoStyles.text)}
              languageItemKey="torque_calc_instructions_line_3"
              defaultText="" />
          </View>
        </View>

        <View style={torqueControlsInfoStyles.widgetWrapper}>
          <View style={torqueControlsInfoStyles.widgetWrapperLabel}>
            <UIElementText
              style={StyleSheet.flatten(torqueControlsInfoStyles.text)}
              languageItemKey="center_of_gravity_from_pivot"
              defaultText="Center Of Gravity From Pivot Point" />
          </View>
          <CenterOfGravityWidget
            stylesheet={torqueControlsInfoStyles}
            ConfiguratorState={this.props.ConfiguratorState}
            torqueChanged={this.props.torqueChanged} />
        </View>
        
        <View style={torqueControlsInfoStyles.widgetWrapper}>
          <View style={torqueControlsInfoStyles.widgetWrapperLabel}>
            <UIElementText
              style={StyleSheet.flatten(torqueControlsInfoStyles.text)}
              languageItemKey="rotation_angle"
              defaultText="Rotation Angle" />
          </View>
          <RotationWidget
            stylesheet={torqueControlsInfoStyles}
            ConfiguratorState={this.props.ConfiguratorState}
            torqueChanged={this.props.torqueChanged} />
        </View>
      </View>
    );
  }
}

RotationAngle.propTypes = {
  isActive: PropTypes.bool,
  ConfiguratorState: PropTypes.object,
  torqueChanged: PropTypes.func
};

export default RotationAngle;
